<template>
    <div class="module-block">
        <div v-bind:class="{
            'block-blur': hasModule === false
        }">
            <slot></slot>
        </div>
        <h2 class="module-disabled" v-if="!hasModule">
            "{{ module }}" module<br>
            is disabled
        </h2>
    </div>
</template>

<script>
export default {
    name: 'ModuleBlockComponent',
    props: {
        module: {
            type: String
        },
        hasModule: {
            type: Boolean,
            default: false,
        },
    }
};
</script>

<style lang="sass" scoped>
.module-block
    position: relative

.module-disabled
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    text-align: center
    z-index: 3

.block-blur
    padding-top: 5px

.block-blur:after
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: #FFFFFF
    opacity: 0.8
    border-radius: 10px
    z-index: 2
</style>
