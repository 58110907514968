<template>
    <page-title-component>
        User game sessions
    </page-title-component>

    <div v-if="gameSessions.length === 0">
        <block-component>
            <h2>
                Nothing found
            </h2>
        </block-component>
    </div>

    <block-component
        v-for="(gameSession, index) in gameSessions"
        :key="index"
        :title="gameSession.created_at + ' - ' + gameSession.hash"
    >
        <apexchart
            :options="getGameSessionsOptions()"
            :series="getGameSessionsSeries(gameSession)"
            :height="300"
            type="area"
        ></apexchart>
    </block-component>
</template>

<script>
export default {
    name: 'UserGameSessionsPage',
    props: {
        gameSessions: {
            required: true,
            type: Object,
        },
    },
    methods: {
        getGameSessionsOptions() {
            return {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'HH:mm:ss',
                    }
                },
            };
        },
        getGameSessionsSeries(gameSession) {
            return [
                {
                    name: 'Count',
                    data: gameSession.histories,
                },
            ];
        },
    },
}
</script>

<style scoped>

</style>
