<template>
    <div class="d-flex justify-content-end align-items-center">
        <b
            v-if="label"
            class="me-2"
        >
            {{ label }}
        </b>

        <input
            v-model="start"
            :name="getStartName()"
            type="date"
            class="form-control w-auto me-2"
            placeholder="01.01.2000"
        >

        <input
            v-model="end"
            :name="getEndName()"
            type="date"
            class="form-control w-auto me-2"
            placeholder="01.01.2022"
        >

        <button
            class="settings me-2"
            type="button"
            data-bs-toggle="dropdown"
        >
        </button>

        <button
            class="apply"
            type="button"
            @click="submitForm"
        >
            Apply
        </button>

        <div class="dropdown-menu">
            <button
                @click="onTodayClicked"
                class="period"
                type="button"
            >
                Today
            </button>

            <button
                @click="onWeekClicked"
                class="period"
                type="button"
            >
                Week
            </button>

            <button
                @click="onMonthClicked"
                class="period"
                type="button"
            >
                Month
            </button>

            <h4 v-if="props.length > 0">
                Seasons:
            </h4>

            <button
                v-for="season in props"
                @click="onSeasonClicked(season)"
                class="period"
                type="button"
            >
                {{ season.caption }} ({{ getSeasonStart(season.start_at) }} - {{ getSeasonEnd(season.stop_at) }})
            </button>

            <red-button-component
                @click="onResetClicked"
                button
            >
                Reset
            </red-button-component>
        </div>
    </div>

    <button
        :id="getId()"
        class="d-none"
        type="submit"
    ></button>
</template>

<script>
export default {
    name: 'PeriodFilterComponent',
    emits: [
        'changed',
    ],
    props: {
        name: {
            type: String,
            required: true,
        },
        seasons: {
            type: Object,
            default: {},
        },
        from: {
            type: String,
            default: '',
        },
        to: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        props: {
            type: Object,
            default: {},
        },
        signal: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.start = this.from;
        this.end = this.to;
    },
    data() {
        return {
            start: '',
            end: '',
        };
    },
    methods: {
        getId() {
            return 'filters-' + this.name + '-submit-button';
        },
        getStartName() {
            return 'filters[' + this.name + '][start]';
        },
        getEndName() {
            return 'filters[' + this.name + '][end]';
        },
        onTodayClicked() {
            const currentDate = new Date();

            this.start = currentDate.getFullYear()
                + '-'
                + (currentDate.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + currentDate.getDate().toString().padStart(2, '0');

            this.end = this.start;

            this.submitForm();
        },
        onWeekClicked() {
            const currentDate = new Date;

            const firstDay = new Date();
            firstDay.setDate(currentDate.getDate() - 7);

            const lastDay = new Date();
            lastDay.setDate(currentDate.getDate());

            this.start = firstDay.getFullYear()
                + '-'
                + (firstDay.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + firstDay.getDate().toString().padStart(2, '0');

            this.end = lastDay.getFullYear()
                + '-'
                + (lastDay.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + lastDay.getDate().toString().padStart(2, '0');

            this.submitForm();
        },
        onMonthClicked() {
            const currentDate = new Date;

            const firstDay = new Date();
            firstDay.setDate(currentDate.getDate() - 30);

            const lastDay = new Date();
            lastDay.setDate(currentDate.getDate());

            this.start = firstDay.getFullYear()
                + '-'
                + (firstDay.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + firstDay.getDate().toString().padStart(2, '0');

            this.end = lastDay.getFullYear()
                + '-'
                + (lastDay.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + lastDay.getDate().toString().padStart(2, '0');

            this.submitForm();
        },
        onSeasonClicked(season) {
            //season date format: ##.##.#### ##:##
            let startDate = season.start_at.split(' ')[0].split('.');
            let endDate = season.stop_at.split(' ')[0].split('.');

            const dateStart = new Date(startDate[2],startDate[1] - 1,startDate[0]);
            const dateEnd = new Date(endDate[2],endDate[1] - 1,endDate[0]);

            this.start = dateStart.getFullYear()
                + '-'
                + (dateStart.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + dateStart.getDate().toString().padStart(2, '0');

            this.end = dateEnd.getFullYear()
                + '-'
                + (dateEnd.getMonth() + 1).toString().padStart(2, '0')
                + '-'
                + dateEnd.getDate().toString().padStart(2, '0');

            this.submitForm();
        },
        onResetClicked() {
            window.location = window.location.href.split('?')[0];
        },
        submitForm() {
            if (this.signal) {
                setTimeout(() => {
                    this.$emit('changed', this.start, this.end);
                }, 300);
            } else {
                const id = this.getId();

                setTimeout(function () {
                    document.getElementById(id).click();
                }, 300);
            }
        },
        getSeasonStart(datetime) {
            const date = new Date(
                datetime.substring(6, 11),
                parseInt(datetime.substring(3, 5)) - 1,
                datetime.substring(0, 2),
                datetime.substring(11, 13),
                datetime.substring(14, 16),
            );

            return date.getDate().toString().padStart(2, '0')
                + '.'
                + (date.getMonth() + 1).toString().padStart(2, '0');
        },
        getSeasonEnd(datetime) {
            const date = new Date(
                datetime.substring(6, 11),
                parseInt(datetime.substring(3, 5)) - 1,
                datetime.substring(0, 2),
                datetime.substring(11, 13),
                datetime.substring(14, 16),
            );

            return date.getDate().toString().padStart(2, '0')
                + '.'
                + (date.getMonth() + 1).toString().padStart(2, '0');
        },
    },
}
</script>

<style lang="sass" scoped>
.settings
    width: 34px
    height: 34px
    background-color: #0091FF
    background-image: url('@images/settings.svg')
    background-position: center
    background-repeat: no-repeat
    border: 0
    border-radius: 5px

.dropdown-menu
    padding: 10px
    border: 0
    box-shadow: 0 0 40px #EEEEEE, 0 4px 8px #42474C0D

.period
    display: block
    width: 100%
    margin-bottom: 6px
    padding: 6px 12px
    background-color: #F5F9FE
    font-weight: 500
    border: 0
    border-radius: 5px

    &:hover
        background-color: #0091FF
        color: #fff

.apply
    padding: 6px 12px
    background-color: #0091FF
    color: #fff
    border: 0
    border-radius: 5px
</style>
