<template>
    <div class="mb-4">
        <label
            v-if="label"
            :for="name"
            class="form-label"
        >
            {{ label }}
        </label>

        <textarea
            :required="required"
            :value="value"
            :name="name"
            :placeholder="placeholder"
            :class="inputClass"
            :style="{ ...styles }"
            :id="name"
            :minlength=minlength
            :maxlength=maxlength
            :rows="rows"
            @input="onInput"
        ></textarea>

        <div
            v-if="error"
            class="invalid-feedback"
            v-html="getError()"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'TextAreaFormGroupComponent',
    props: {
        value: {
            type: String,
        },
        name: {
            type: String,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        error: {
            type: String,
        },
        styles: {
            type: Object,
        },
        required: {
            type: Boolean,
            default: false
        },
        minlength: {
            type: [Number,String],
        },
        maxlength: {
            type: [Number,String],
        },
        rows: {
            type: [String, Number],
            default: 4,
        }
    },
    data() {
        return {
            inputClass: {
                'form-control': true,
                'is-invalid': this.error,
            },
        };
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },
        getError() {
            if (Array.isArray(this.error)) {
                return this.error.join('<br>');
            }

            return this.error;
        }
    },
};
</script>

<style lang="sass" scoped>
.form-label
    font-weight: 600
</style>
