<template>
    <page-title-component>
        Withdrawal
    </page-title-component>

    <div class="blocks-title">
        <page-title-component>
            {{ this.withdrawal.tg_user.first_name }} {{ this.withdrawal.tg_user.last_name }}
        </page-title-component>

        <div class="withdrawal-buttons">
            <blue-button-component
                :link="executeLink"
            >
                Execute
            </blue-button-component>

            <red-button-component
                :link="deleteLink"
            >
                Delete
            </red-button-component>
        </div>
    </div>

    <div class="blocks">
        <block-component>
            <div class="block__header">
                <h4 class="block__title">
                    Data
                </h4>
            </div>

            <div
                class="detail"
                v-for="(value, key) in getDataInfo()"
            >
                <span class="detail__label">
                    {{ key }}
                </span>

                <a :href="getUserLink()" class="detail__value" v-if="key === 'Username'">
                    {{ value }}
                </a>

                <span class="detail__value" v-else>
                    {{ value }}
                </span>
            </div>
        </block-component>

        <div>
            <block-component>
                <div class="block__header">
                    <h4 class="block__title">
                        Wallets
                    </h4>
                </div>

                <div
                    class="detail"
                    v-for="(value, key) in this.wallets"
                >
                        <span class="detail__label">
                            {{ key }}
                        </span>

                    <span class="detail__value">
                            {{ value }}
                        </span>
                </div>
            </block-component>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WithdrawalViewPage',
    props: {
        withdrawal: {
            type: Object,
            required: true,
            default: {
                tg_user: {
                    username: '',
                    first_name: '',
                    last_name: '',
                },
                withdrawal_date: null,
                remainder: null,
            },
        },
        wallets: {
            type: Array,
            default: []
        },
        executeLink: {
            type: String,
            default: ''
        },
        deleteLink: {
            type: String,
            default: ''
        },
        userLink: {
            type: String,
            default: ''
        }
    },
    methods: {
        getDataInfo() {
            return {
                'Username': this.withdrawal.tg_user.username || this.withdrawal.tg_user.first_name,
                'Email': this.withdrawal.tg_user.email || '-',
                'Phone': this.withdrawal.tg_user.phone || '-',
                'Request date': this.withdrawal.created_at,
                'Date of withdrawal': this.withdrawal.withdrawal_date || '-',
                'Number of current/remaining points': this.withdrawal.hasOwnProperty('remainder') ? this.withdrawal.remainder : '-',
                'Number of points for withdrawal': this.withdrawal.points
            };
        },
        getUserLink() {
            return this.userLink
        }
    }
}
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 370px
    grid-column-gap: 30px

.block
    margin-bottom: 30px
    padding: 30px
    background-color: #fff
    border-radius: 10px

    &_demo
        text-align: center

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 20px
        font-size: 20px

    &__title
        font-size: 22px

.detail
    display: flex
    align-items: center
    justify-content: space-between
    padding: 10px 0
    font-size: 16px
    border-bottom: 1px solid #E7E7E7

    &:last-child
        border-bottom: 0

    &_bordered
        border-bottom: 0

    &__label
        color: #666666

    &__value
        font-weight: 500
        word-break: break-all

.blocks-title
    display: flex
    align-items: center
    justify-content: space-between

.withdrawal-buttons
    display: grid
    grid-template-columns: max-content max-content 1fr
    grid-column-gap: 20px
</style>
