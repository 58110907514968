<template>
    <div class="row">
        <div class="row">

            <div class="col">
                <input-form-group-component
                    label="Creative name"
                    name="name"
                    type="text"
                    maxlength=255
                    placeholder="Enter the name of the task"
                    :value="creative.name"
                    :error="errors.name"
                    required
                ></input-form-group-component>
            </div>
            <div class="col">
                <input-form-group-component
                    label="Points"
                    name="points"
                    type="number"
                    max="2147483647"
                    placeholder="50000"
                    :value="creative.points"
                    :error="errors.points"
                    required
                ></input-form-group-component>
            </div>

        </div>

        <div class="row">
            <div class="col">
                <text-area-form-group-component
                    label="Description"
                    placeholder="Write description of creative"
                    name="description"
                    maxlength=1000
                    rows="12"
                    :value="creative.description"
                    :error="errors.description"
                    required
                ></text-area-form-group-component>
            </div>
            <div class="col">
                <text-area-form-group-component
                    label="Image text"
                    placeholder="Image text"
                    name="image_text"
                    maxlength=1000
                    rows="3"
                    :value="creative.image_text"
                    :error="errors.image_text"
                    required
                ></text-area-form-group-component>

                <input-form-group-component
                    label="Link for qr-code"
                    name="link_for_qr"
                    type="url"
                    :error="errors.link_for_qr || this.linkError"
                    :value="creative.link_for_qr"
                    required
                    @input="changeLink"
                ></input-form-group-component>

                <input-form-group-component
                    label="User checks count"
                    name="user_checks_count"
                    type="number"
                    placeholder="10"
                    :min="creative.max_user_checks_count"
                    :value="creative.user_checks_count"
                    :error="errors.user_checks_count"
                    @change="changeUserChecksCount"
                    required
                ></input-form-group-component>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <text-area-form-group-component
                    label="Send link text"
                    placeholder="Send link"
                    name="send_link_text"
                    maxlength=1000
                    rows="3"
                    :value="creative.send_link_text"
                    :error="errors.send_link_text"
                    required
                ></text-area-form-group-component>

                <text-area-form-group-component
                    label="Creative on check"
                    placeholder="Creative on check"
                    name="creative_checking"
                    maxlength=1000
                    rows="3"
                    :value="creative.creative_checking"
                    :error="errors.creative_checking"
                    required
                ></text-area-form-group-component>
            </div>
            <div class="col">
                <input-form-group-component
                    label="Max user checks count"
                    name="max_user_checks_count"
                    type="number"
                    placeholder="3"
                    min="3"
                    step="2"
                    :max="creative.user_checks_count"
                    :value="creative.max_user_checks_count"
                    :error="errors.max_user_checks_count"
                    @change="changeMaxUserChecksCount"
                    required
                ></input-form-group-component>

                <input-form-group-component
                    label="Points for checking"
                    name="points_for_check"
                    type="number"
                    placeholder="3"
                    :value="creative.points_for_check"
                    :error="errors.points_for_check"
                    required
                ></input-form-group-component>

                <input-form-group-component
                    label="Complete button text"
                    name="complete_button"
                    type="text"
                    maxlength=255
                    placeholder="I complete"
                    :value="creative.complete_button"
                    :error="errors.complete_button"
                    required
                ></input-form-group-component>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <check-box-form-group-component
                name="points_after_check"
                label="Points only after checking another task"
                :checked="!!creative.points_after_check"
            ></check-box-form-group-component>
            <input-form-group-component
                :label="getFileLabel()"
                type="file"
                name="file"
                accept="image/png"
                :error="errors.file"
                :required="!this.dataCreative"
            ></input-form-group-component>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <text-area-form-group-component
                label="Notification that the creative is done"
                placeholder="Creative done"
                name="creative_completed_text"
                maxlength=1000
                rows="5"
                :value="creative.creative_completed_text"
                :error="errors.creative_completed_text"
                required
            ></text-area-form-group-component>
        </div>
        <div class="col">
            <text-area-form-group-component
                label="Notification that the creative is not done"
                placeholder="Creative not done"
                name="creative_not_completed_text"
                maxlength=1000
                rows="5"
                :value="creative.creative_not_completed_text"
                :error="errors.creative_not_completed_text"
                required
            ></text-area-form-group-component>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreativeViewPage",
    props: {
        oldValues: {
            type: Array,
            default: []
        },
        errors: {
            type: Array,
            default: []
        },
        dataCreative: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            creative: {
                name: this.oldValues.name || "",
                description: this.oldValues.description || "",
                points_after_check: this.oldValues.points_after_check ,
                link_for_qr: this.oldValues.link_for_qr || "",
                points: Number.parseInt(this.oldValues.points) || 0,
                user_checks_count: Number.parseInt(this.oldValues.user_checks_count) || 10,
                max_user_checks_count: Number.parseInt(this.oldValues.max_user_checks_count) || 3,
                points_for_check: Number.parseInt(this.oldValues.points_for_check) || 0,
                complete_button: this.oldValues.complete_button || "",
                send_link_text: this.oldValues.send_link_text || "",
                image_text: this.oldValues.image_text || "",
                creative_checking: this.oldValues.creative_checking || "",
                creative_completed_text: this.oldValues.creative_completed_text || "",
                creative_not_completed_text: this.oldValues.creative_not_completed_text || "",
            },
            linkError: ''
        };
    },
    mounted() {
        if (this.dataCreative) {
            this.creative = this.dataCreative;
        }
        this.linkError = this.getLinkError();
    },
    methods: {
        changeLink(value) {
            if (Number.isInteger(value.length)) {
                this.creative.link_for_qr = value;
            }
        },
        changeUserChecksCount(e) {
            this.creative.user_checks_count = e.target.value;
        },
        changeMaxUserChecksCount(e) {
            this.creative.max_user_checks_count = e.target.value;
        },
        getLinkError() {
            let url;

            try {
                url = new URL(this.creative.link_for_qr);
            } catch (_) {
                return 'Must be url';
            }

            return '';
        },
        getFileLabel() {
            if (this.dataCreative) {
                return 'Change background image';
            }
            return 'Background image';
        },
    },
    watch: {
        creative: {
            handler: function () {
                this.linkError = this.getLinkError();
            },
            deep: true
        }
    }

}
</script>

<style scoped>

</style>
