<template>
    <div class="d-flex align-items-center justify-content-center">
        <img src="@images/loader.svg" class="me-2" alt="loader">
        Loading...
    </div>
</template>

<script>
export default {
    name: 'LoaderComponent'
}
</script>

<style lang="sass" scoped>
</style>
