<template>
    <template v-if="isVisible">
        <div class="card mb-4">
            <div class="card-header">
                <div class="w-25">
                    <red-button-component
                        @click="onRemoveClicked"
                        button
                    >
                        Remove route
                    </red-button-component>
                </div>
            </div>

            <div class="card-body">
                <select-form-group-component
                    :data="getSelectData()"
                    :selected="getRoute()"
                    :name="getSelectName()"
                    label="Route"
                    required
                ></select-form-group-component>

                <input-form-group-component
                    :name="getDescriptionName()"
                    :value="getDescriptionValue()"
                    label="Description"
                    type="text"
                    placeholder="Max 256 characters"
                    minLength="1"
                    maxLength="256"
                    required
                ></input-form-group-component>

                <input-form-group-component
                    :name="getSortName()"
                    :value="getSortValue()"
                    label="Sort"
                    type="number"
                    placeholder="0"
                    required
                ></input-form-group-component>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    name: 'TelegramMenuItemComponent',
    props: {
        index: {
            type: Number,
            required: true,
        },
        routeProps: {
            type: Object,
            required: true,
        },
        menuRouteProp: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getSelectData() {
            const result = {};

            this.routeProps.forEach(function (item) {
                result[item.id] = item.command;
            });

            return result;
        },
        getRoute() {
            return this.menuRouteProp.id;
        },
        getSelectName() {
            return 'tg_routes[' + this.index + '][id]';
        },
        getDescriptionName() {
            return 'tg_routes[' + this.index + '][description]';
        },
        getDescriptionValue() {
            return this.menuRouteProp.description;
        },
        getSortName() {
            return 'tg_routes[' + this.index + '][sort]';
        },
        getSortValue() {
            return this.menuRouteProp.sort;
        },
        onRemoveClicked() {
            this.isVisible = false;
        },
    },
    data() {
        return {
            isVisible: true,
        };
    },
}
</script>

<style scoped>

</style>
