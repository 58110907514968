<template>
    <div
        v-if="isShow"
        class="card mb-4"
    >
        <div class="card-header text-end">
            <button
                @click="onRemoveClicked"
                class="btn-close"
                type="button"
            ></button>
        </div>

        <div class="card-body">
            <select-form-group-component
                :data="types"
                :name="getTypeName()"
                :selected="getTypeSelected()"
                label="Select input type"
                required
            ></select-form-group-component>

            <input-form-group-component
                :name="getKeyName()"
                :value="getKeyValue()"
                label="Macro key (no spaces)"
                placeholder="email"
                required
            ></input-form-group-component>

            <text-area-form-group-component
                :name="getRequestTextName()"
                :value="getRequestValue()"
                label="Request text"
                placeholder="Enter your email"
            ></text-area-form-group-component>

            <text-area-form-group-component
                :name="getErrorTextName()"
                :value="getErrorValue()"
                label="Error text"
                placeholder="Incorrect email, please, try again with correct data"
            ></text-area-form-group-component>

            <check-box-form-group-component
                :name="getCheckIfAlreadyExistName()"
                label="Check if the value already exists"
                :checked="getCheckIfAlreadyExistValue()"
                @change="onChangeCheckAlreadyExist()"
            ></check-box-form-group-component>

            <text-area-form-group-component
                v-if="checkIfAlreadyExist"
                :name="getAlreadyExistTextName()"
                :value="getAlreadyExistValue()"
                label="Text message already exists"
                placeholder="Email already exists"
            ></text-area-form-group-component>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TelegramFormComponent',
    created() {
        this.form = this.item;
        this.checkIfAlreadyExist = this.form.check_if_already_exist
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        types: {
            type: Object,
            default: {},
        },
        item: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            isShow: true,
            form: {},
            checkIfAlreadyExist: false
        };
    },
    methods: {
        onRemoveClicked() {
            this.isShow = false;
        },
        getTypeName() {
            return 'tg_forms[' + this.index + '][type]';
        },
        getTypeSelected() {
            if (this.form.hasOwnProperty('validation_method')) {
                return this.form.validation_method;
            }
        },
        getRequestTextName() {
            return 'tg_forms[' + this.index + '][request_text]';
        },
        getRequestValue() {
            if (this.form.hasOwnProperty('request_text')) {
                return this.form.request_text;
            }

            return '';
        },
        getErrorTextName() {
            return 'tg_forms[' + this.index + '][error_text]';
        },
        getErrorValue() {
            if (this.form.hasOwnProperty('error_text')) {
                return this.form.error_text;
            }

            return '';
        },
        getKeyName() {
            return 'tg_forms[' + this.index + '][key]';
        },
        getKeyValue() {
            if (this.form.hasOwnProperty('key')) {
                return this.form.key;
            }

            return '';
        },
        getCheckIfAlreadyExistName() {
            return 'tg_forms[' + this.index + '][check_if_already_exist]';
        },
        getCheckIfAlreadyExistValue() {
            if (this.form.hasOwnProperty('check_if_already_exist')) {
                return !!this.form.check_if_already_exist;
            }

            return false;
        },
        getAlreadyExistTextName() {
            return 'tg_forms[' + this.index + '][already_exist_text]';
        },
        getAlreadyExistValue() {
            if (this.form.hasOwnProperty('already_exist_text')) {
                return this.form.already_exist_text;
            }

            return '';
        },
        onChangeCheckAlreadyExist() {
            this.checkIfAlreadyExist = !this.checkIfAlreadyExist;
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
