<template>
    <div class="form-check mb-4">
        <input
            type="checkbox"
            id="flexCheckDefault"
            class="form-check-input"
            name="filters[all]"
            @change="onAllUsersChanged"
            :checked="filters?.all"
        >

        <label class="form-check-label" for="flexCheckDefault">
            {{ all_message }}
        </label>

        <div class="text-secondary">
            {{ all_hint }}
        </div>
    </div>

    <h2>
        Filters
    </h2>
    <p class="text-secondary">
        Filters are divided into blocks.
        <br>
        Inside the block between filters work on the principle of "OR".
        <br>
        The "And" rule works between blocks.
    </p>

    <div class="row gx-5">
        <div
            v-if="systemLanguages && systemLanguages.length > 0 && hasFilterEnabled('language')"
            class="col-6"
        >
            <div class="accordion accordion-flush mb-4" id="filter_include_1">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filter_include_1_body">
                            Segmentation by system languages
                        </button>
                    </h2>

                    <div id="filter_include_1_body" class="accordion-collapse collapse show" data-bs-parent="#filter_include_1">
                        <div class="accordion-body">
                            <div
                                v-for="(language, key) in systemLanguages"
                                :key="key"
                                class="d-flex mb-4"
                            >
                                <label
                                    :for="getIdForInput('system_languages_include', key)"
                                    class="form-check-label flex-fill"
                                >
                                    {{ language.caption }}
                                </label>

                                <input
                                    :id="getIdForInput('system_languages_include', key)"
                                    :checked="hasSystemLanguage(language)"
                                    :disabled="isAllClicked"
                                    :name="getIncludeSystemLanguageName(language)"
                                    type="checkbox"
                                    class="form-check-input"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6" v-if="hasFilterEnabled('referral')">
            <div class="accordion accordion-flush mb-4" id="filter_include_2">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filter_include_2_body">
                            Segmentation by referrals
                        </button>
                    </h2>

                    <div id="filter_include_2_body" class="accordion-collapse collapse show" data-bs-parent="#filter_include_2">
                        <div class="accordion-body">
                            <div class="d-flex mb-4">
                                <label
                                    for="include_referrals_once_invite"
                                    class="form-check-label flex-fill"
                                >
                                    Who once invited
                                </label>

                                <input
                                    :disabled="isAllClicked"
                                    :checked="filters?.include?.referrals?.once_invite"
                                    id="include_referrals_once_invite"
                                    name="filters[include][referrals][once_invite]"
                                    type="checkbox"
                                    class="form-check-input"
                                >
                            </div>

                            <div class="d-flex mb-4">
                                <label
                                    for="include_referrals_never_invite"
                                    class="form-check-label flex-fill"
                                >
                                    Who never invited
                                </label>

                                <input
                                    :disabled="isAllClicked"
                                    :checked="filters?.include?.referrals?.never_invite"
                                    id="include_referrals_never_invite"
                                    name="filters[include][referrals][never_invite]"
                                    type="checkbox"
                                    class="form-check-input"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6" v-if="tasks && hasFilterEnabled('tasks')">
            <div class="accordion accordion-flush mb-4" id="filter_include_3">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filter_include_3_body">
                            Segmentation by completed tasks
                        </button>
                    </h2>

                    <div id="filter_include_3_body" class="accordion-collapse collapse show" data-bs-parent="#filter_include_3">
                        <div class="accordion-body">
                            <div
                                v-for="(task, key) in tasks"
                                :key="key"
                                class="d-flex mb-4"
                            >
                                <label
                                    :for="getIdForInput('task_include', key)"
                                    class="form-check-label flex-fill"
                                >
                                    {{ task.name }}
                                </label>

                                <input
                                    :id="getIdForInput('task_include', key)"
                                    :name="getIncludeTaskName(task)"
                                    :checked="hasCompletedTask(task)"
                                    type="checkbox"
                                    class="form-check-input"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6" v-if="hasFilterEnabled('captcha')">
            <div class="accordion accordion-flush mb-4" id="filter_include_4">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filter_include_4_body">
                            Segmentation by captcha
                        </button>
                    </h2>

                    <div id="filter_include_4_body" class="accordion-collapse collapse show" data-bs-parent="#filter_include_4">
                        <div class="accordion-body">
                            <div class="d-flex mb-4">
                                <label
                                    for="include_captchas_passed"
                                    class="form-check-label flex-fill"
                                >
                                    Who passed captcha
                                </label>

                                <input
                                    :disabled="isAllClicked"
                                    :checked="filters?.include?.captchas?.passed"
                                    id="include_captchas_passed"
                                    name="filters[include][captchas][passed]"
                                    type="checkbox"
                                    class="form-check-input"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TelegramFiltersComponent',
    props: {
        all_message: {
            type: String,
            default: 'Send to all users.',
        },
        all_hint: {
            type: String,
            default: 'Customer types and recipient groups will be ignored',
        },
        systemLanguages: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: false,
        },
        tasks: {
            type: Object,
            required: false,
        },
        enabledFilters: {
            type: Object,
            required: false,
        }
    },
    mounted() {
        this.isAllClicked = this.filters?.all;
    },
    methods: {
        getIdForInput(name, index) {
            return name + '_' + index;
        },
        getIncludeSystemLanguageName(language) {
            return 'filters[include][system_languages][' + language.id + ']';
        },
        getExcludeSystemLanguageName(language) {
            return 'filters[exclude][system_languages][' + language.id + ']';
        },
        getIncludeTaskName(task) {
            return 'filters[include][completed_tasks][' + task.id + ']';
        },
        getExcludeTaskName(task) {
            return 'filters[exclude][completed_tasks][' + task.id + ']';
        },
        onAllUsersChanged(event) {
            this.isAllClicked = !this.isAllClicked;
        },
        hasSystemLanguage(language) {
            const systemLanguageIds = this.filters?.include?.system_languages;

            if (!systemLanguageIds) {
                return false;
            }

            const keys = Object.keys(systemLanguageIds);
            return keys.includes(language.id.toString());
        },
        hasCompletedTask(task) {
            const completedTasks = this.filters?.include?.completed_tasks;

            if (!completedTasks) {
                return false;
            }

            const keys = Object.keys(this.filters?.include?.completed_tasks);
            return keys.includes(task.id.toString());
        },
        hasFilterEnabled(type) {
            if(this.enabledFilters) {
                const values = Object.values(this.enabledFilters);
                return values.includes(type);
            } else {
                return true;
            }
        }
    },
    data() {
        return {
            isAllClicked: false,
        };
    },
}
</script>

<style lang="sass" scoped>
.accordion-button
    background-color: #0091FF
    color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 5px !important

    &_orange
        background-color: #FF936B

.accordion-body
    font-size: 16px
    font-weight: 500
    border-right: 1px solid #D6D6D6
    border-bottom: 1px solid #D6D6D6
    border-left: 1px solid #D6D6D6
    border-bottom-right-radius: 5px
    border-bottom-left-radius: 5px
</style>
