<template>
    <module-block-component
        module="Tasks"
        :has-module="hasTaskModule"
    >
        <completed-tasks-growth-component
            :seasons="seasons"
            :prop-filters="tasksFilters"
            :has-task-module="hasTaskModule"
        ></completed-tasks-growth-component>
    </module-block-component>

    <completed-tasks-statistics-component
        :prop-filters="getFiltersTasks()"
        :has-task-module="hasTaskModule"
    ></completed-tasks-statistics-component>

    <completed-tasks-component
        :filters-for-completed="tasksFilters"
        :filters-for-checking="getFiltersForCheckingTasks()"
        :has-task-module="hasTaskModule"
    ></completed-tasks-component>
</template>

<script>

export default {
    name: 'TaskAnalyticsPage',
    props: {
        tasksFilters: {
            type: Array,
            default: []
        },
        seasons: {
            type: Object,
            required: true,
        },
        hasTaskModule: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFiltersForCheckingTasks() {
            return this.tasksFilters.filter((filter) => filter.type === 'unit');
        },
        getFiltersTasks() {
            return this.tasksFilters.filter((filter) => filter.type === 'task');
        }
    },
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.block
    position: relative
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 34px
        height: 34px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__image
        width: 20px

    &__line
        display: grid
        grid-template-columns: max-content 1fr 60px 120px
        grid-column-gap: 10px
        align-items: center
        padding: 10px

    &__income
        padding: 4px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px
</style>
