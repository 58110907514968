<template>
    <block-component>
        <div class="tabs nav nav-tabs">
            <a class="tabs__tab active"
               data-bs-toggle="tab" data-bs-target="#completed">
                Users who have completed the tasks
            </a>
            <a class="tabs__tab"
               data-bs-toggle="tab" data-bs-target="#checking">
                Users who submitted tasks for checking
            </a>
        </div>

        <div class="card-body">
            <div class="tab-content">
                <div id="completed" class="tab-pane fade show active">
                    <template v-if="usersCompetedTasks.isLoading">
                        <loader-component></loader-component>
                    </template>

                    <module-block-component
                        module="Tasks"
                        :has-module="hasTaskModule"
                    >
                        <table-component
                            :data="getUserCompleted()"
                            :columns="usersCompetedTasks.columns"
                            :attributes="usersCompetedTasks.attributes"
                            search-name="search"
                            view
                            search
                            :search-form="false"
                            @changeSearch="changeSearchStringUserCompeted"
                        >
                            <template v-slot:filters>
                                <filter-component
                                    :props="filtersForCompleted"
                                    label="Filter by tasks"
                                    @changed="onChangeFiltersCompletedTasks"
                                ></filter-component>
                            </template>
                            <template v-slot:pagination>
                                <pagination-component
                                    :data="getUserCompleted()"
                                    @change="onChangePageCompletedTasks"
                                ></pagination-component>
                            </template>
                        </table-component>
                    </module-block-component>
                </div>

                <div id="checking" class="tab-pane fade">
                    <template v-if="usersCheckingTasks.isLoading">
                        <loader-component></loader-component>
                    </template>

                    <module-block-component
                        module="Tasks"
                        :has-module="hasTaskModule"
                    >
                        <table-component
                            :data="getUserChecking()"
                            :columns="usersCheckingTasks.columns"
                            :attributes="usersCheckingTasks.attributes"
                            search-name="search"
                            view
                            search
                            :search-form="false"
                            @changeSearch="changeSearchStringUserChecking"
                        >
                            <template v-slot:filters>
                                <filter-component
                                    :props="filtersForChecking"
                                    label="Filter by tasks"
                                    @changed="onChangeFiltersCheckingTasks"
                                ></filter-component>
                            </template>
                            <template v-slot:pagination>
                                <pagination-component
                                    :data="getUserChecking()"
                                    @change="onChangePageCheckingTasks"
                                ></pagination-component>
                            </template>
                        </table-component>
                    </module-block-component>
                </div>
            </div>
        </div>

    </block-component>
</template>

<script>
export default {
    name: 'CompletedTasksComponent',
    props: {
        task: {
            type: Object,
            required: false,
        },
        filtersForCompleted: {
            type: Array,
            default: []
        },
        filtersForChecking: {
            type: Array,
            default: []
        },
        hasTaskModule: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            usersCompetedTasks: {
                data: {
                    current_page: 1
                },
                isLoading: false,
                searchString: '',
                filters: [],
                columns: [
                    'Name',
                    'Username',
                    'Point count',
                    'Twitter username'
                ],
                attributes: [
                    'first_name',
                    'username',
                    'balance_sum_point',
                    'tg_bot_tg_users.0.twitter_username'
                ]
            },
            usersCheckingTasks: {
                data: {
                    current_page: 1
                },
                isLoading: false,
                searchString: '',
                filters: [],
                columns: [
                    'Name',
                    'Username',
                    'Point count',
                    'Task title',
                    'Twitter username',
                    'Type of task'
                ],
                attributes: [
                    'tg_user.first_name',
                    'tg_user.username',
                    'task_unit.points',
                    'task_unit.name',
                    'tg_bot_user.twitter_username',
                    'task_unit.string_type'
                ]
            }
        }
    },
    mounted() {
        this.uploadUsersCompletedTasks();
        this.uploadUsersCheckingTasks();
    },
    methods: {
        // completed tasks
        async uploadUsersCompletedTasks() {
            if (!this.hasTaskModule) {
                this.usersCompetedTasks.data = this.getFakeCompletedTasksUsersData();
                return;
            }
            this.usersCompetedTasks.isLoading = true;
            let filters = this.usersCompetedTasks.filters;
            let by_any_task = false;
            if (filters.length === 0 && this.task) {
                by_any_task = true;
            }
            axios.post('/analytics/users-completed-tasks', {
                page: this.usersCompetedTasks.data.current_page,
                search: this.usersCompetedTasks.searchString,
                filters: filters,
                by_any_task: by_any_task
            }).then((result) => {
                this.usersCompetedTasks.data = result.data;
                this.usersCompetedTasks.isLoading = false;
            });
        },
        getUserCompleted() {
            return this.usersCompetedTasks.data;
        },
        onChangePageCompletedTasks(page) {
            this.usersCompetedTasks.data.current_page = page;
            this.uploadUsersCompletedTasks();
        },
        onChangeFiltersCompletedTasks(filters) {
            this.usersCompetedTasks.filters = filters;
            this.usersCompetedTasks.data.current_page = 1;
            this.uploadUsersCompletedTasks();
        },
        changeSearchStringUserCompeted(search) {
            if (this.usersCompetedTasks.searchString !== search) {
                this.usersCompetedTasks.data.current_page = 1;
                this.usersCompetedTasks.searchString = search;
                this.uploadUsersCompletedTasks();
            }
        },

        // checking tasks
        async uploadUsersCheckingTasks() {
            if (!this.hasTaskModule) {
                this.usersCheckingTasks.data = this.getFakeCheckingTasksData();
                return;
            }
            this.usersCheckingTasks.isLoading = true;
            let filters = this.usersCheckingTasks.filters;
            axios.post('/analytics/users-checking-tasks', {
                page: this.usersCheckingTasks.data.current_page,
                search: this.usersCheckingTasks.searchString,
                filters: filters
            }).then((result) => {
                this.usersCheckingTasks.data = result.data;
                this.usersCheckingTasks.isLoading = false;
            });
        },
        getUserChecking() {
            return this.usersCheckingTasks.data;
        },
        onChangePageCheckingTasks(page) {
            this.usersCheckingTasks.data.current_page = page;
            this.uploadUsersCheckingTasks();
        },
        onChangeFiltersCheckingTasks(filters) {
            this.usersCheckingTasks.filters = filters;
            this.usersCheckingTasks.data.current_page = 1;
            this.uploadUsersCheckingTasks();
        },
        changeSearchStringUserChecking(search) {
            if (this.usersCheckingTasks.searchString !== search) {
                this.usersCheckingTasks.searchString = search;
                this.usersCheckingTasks.data.current_page = 1;
                this.uploadUsersCheckingTasks();
            }
        },
        getFakeCompletedTasksUsersData() {
            let result = [];

            for (let i = 0; i < 5; i++) {
                result.push({
                    first_name: '',
                    username: '',
                    balance_sum_point: '',
                    tg_bot_tg_users: [{twitter_username:''}],
                    table_url_view: window.location.href
                });
            }
            return result;
        },
        getFakeCheckingTasksData() {
            let result = [];

            for (let i = 0; i < 5; i++) {
                result.push({
                    tg_user: {
                        first_name: '',
                        username: ''
                    },
                    task_unit: {
                        points: '',
                        name: '',
                        string_type: ''
                    },
                    tg_bot_user:{twitter_username: ''},
                    table_url_view: window.location.href
                })
            }
            return result;
        }
    }
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.block
    position: relative
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 40px
        height: 40px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__image
        width: 20px

    &__line
        display: grid
        grid-template-columns: max-content 1fr 60px 60px
        grid-column-gap: 10px
        align-items: center
        padding: 10px 0

    &__income
        padding: 4px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px
</style>
