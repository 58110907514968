<template>
    <img src="@images/season.svg" alt="icon">
</template>

<script>
export default {
    name: 'SeasonImageComponent'
}
</script>

<style scoped>

</style>
