<template>
    <div class="block">
        <h4 class="block__title">
            <img class="me-2" src="@images/analytics/analytics_overview.svg" alt="icon">
            Overview
        </h4>

        <template v-if="overview.isLoading">
            <div class="h-100">
                <loader-component></loader-component>
            </div>
        </template>

        <template v-else>
            <div
                v-for="(value, key) in overview.data"
                :key="key"
                class="block__line"
            >
                <span class="block__icon">
                    <img
                        v-if="getOverviewIcon(key)"
                        :src="getOverviewIcon(key)"
                        class="block__image"
                        alt="icon"
                    >
                </span>

                <span>
                    {{ getOverviewCaption(key) }}
                </span>

                <span class="block__income text-center">
                    {{
                        (parseInt(value.income) > 0)
                            ? '+' + value.income
                            : value.income
                    }}
                </span>

                <span class="text-center">
                    {{ value.total }}
                </span>
            </div>
        </template>
    </div>

    <div class="blocks">
        <div class="block">
            <h4 class="block__title">
                <img class="me-2" src="@images/analytics/analytics_languages.svg" alt="icon">
                Telegram languages
            </h4>

            <div v-if="telegramLanguages.isLoading">
                <loader-component></loader-component>
            </div>

            <div v-else>
                <div v-if="Object.keys(telegramLanguages.data).length > 0">
                    <apexchart
                        :options="getTelegramLanguageOptions()"
                        :series="getTelegramLanguageSeries()"
                        height="300"
                        type="donut"
                    ></apexchart>
                </div>

                <div v-else>
                    Languages not found
                </div>
            </div>
        </div>

        <div class="block">
            <h4 class="block__title">
                <img class="me-2" src="@images/analytics/analytics_languages.svg" alt="icon">
                System languages
            </h4>

            <div v-if="systemLanguages.isLoading">
                <loader-component></loader-component>
            </div>

            <div v-else>
                <div v-if="Object.keys(systemLanguages.data).length > 0">
                    <apexchart
                        :options="getSystemLanguageOptions()"
                        :series="getSystemLanguageSeries()"
                        height="300"
                        type="donut"
                    ></apexchart>
                </div>

                <div v-else>
                    Languages not found
                </div>
            </div>
        </div>

        <div class="block">
            <div class="block__title_line">
                <h4 class="block__title">
                    <img class="me-2" src="@images/analytics/analytics_chart.svg" alt="icon">
                    Hourly activity
                </h4>

                <period-filter-component
                    v-if="!hourActivity.isLoading"
                    :props="seasons"
                    name="date"
                    @changed="onHourlyActivityFiltersChanged"
                    signal
                    :from="hourActivity.start"
                    :to="hourActivity.end"
                ></period-filter-component>
            </div>

            <div v-if="hourActivity.isLoading">
                <loader-component></loader-component>
            </div>

            <div v-else>
                <div v-if="Object.keys(hourActivity.data).length > 0">
                    <apexchart
                        :options="getHourlyActivityOptions()"
                        :series="getHourlyActivitySeries()"
                        height="300"
                        type="radar"
                    ></apexchart>
                </div>

                <div v-else>
                    Data not found
                </div>
            </div>
        </div>
    </div>

    <div class="block">
        <div class="block__title_line">
            <h4 class="block__title">
                <img
                    src="@images/analytics/analytics_chart.svg"
                    class="me-2"
                    alt="icon"
                >
                User growth
            </h4>

            <period-filter-component
                v-if="!usersGrowth.isLoading"
                :props="seasons"
                name="created_at"
                @changed="onUsersGrowthFiltersChanged"
                signal
                :from="usersGrowth.start"
                :to="usersGrowth.end"
            ></period-filter-component>
        </div>

        <div v-if="usersGrowth.isLoading">
            <loader-component></loader-component>
        </div>

        <div v-else>
            <apexchart
                :options="getUsersGrowthOptions()"
                :series="getUsersGrowthSeries()"
                :height="400"
                type="area"
            ></apexchart>
        </div>
    </div>

    <div class="block">
        <div class="block__title_line">
            <h4 class="block__title">
                <img
                    src="@images/analytics/analytics_chart.svg"
                    class="me-2"
                    alt="icon"
                >
                Referrals growth
            </h4>

            <period-filter-component
                v-if="!referralsGrowth.isLoading"
                :props="seasons"
                name="created_at"
                @changed="onReferralsGrowthFiltersChanged"
                signal
                :from="referralsGrowth.start"
                :to="referralsGrowth.end"
            ></period-filter-component>
        </div>

        <div v-if="referralsGrowth.isLoading">
            <loader-component></loader-component>
        </div>

        <div v-else>
            <apexchart
                :options="getReferralsGrowthOptions()"
                :series="getReferralsGrowthSeries()"
                :height="400"
                type="area"
            ></apexchart>
        </div>
    </div>

    <div class="block">
        <div class="block__title_line">
            <h4 class="block__title">
                <img
                    src="@images/analytics/analytics_chart.svg"
                    class="me-2"
                    alt="icon"
                >
                Game sessions
            </h4>

            <period-filter-component
                v-if="!gameSessions.isLoading"
                :props="seasons"
                name="created_at"
                @changed="onGameSessionsFiltersChanged"
                signal
                :from="gameSessions.start"
                :to="gameSessions.end"
            ></period-filter-component>
        </div>

        <div v-if="gameSessions.isLoading">
            <loader-component></loader-component>
        </div>

        <div v-else>
            <apexchart
                :options="getGameSessionsOptions()"
                :series="getGameSessionsSeries()"
                :height="400"
                type="area"
            ></apexchart>
        </div>

    </div>

    <div class="block">
        <h4 class="block__title">
            Overview game
        </h4>

        <div v-if="gameData.isLoading">
        </div>

        <div v-else>
            <div class="block__line">
                <span>Mark chosen</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.starts_0 }}</span>
            </div>
            <div class="block__line">
                <span>Elon chosen</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.starts_1 }}</span>
            </div>
            <div class="block__line">
                <span>Nobody win</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.wins_1 }}</span>
            </div>
            <div class="block__line">
                <span>Mark win (user)</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.wins_2 }}</span>
            </div>
            <div class="block__line">
                <span>Elon win (user)</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.wins_3 }}</span>
            </div>
            <div class="block__line">
                <span>Mark win (PC)</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.wins_4 }}</span>
            </div>
            <div class="block__line">
                <span>Elon win (PC)</span>
                <span></span>
                <span></span>
                <span class="text-center">{{ gameData.data.wins_5 }}</span>
            </div>
        </div>
    </div>

    <div class="block">
        <div class="block__title_line">
            <h4 class="block__title">
                <img
                    src="@images/analytics/analytics_top.svg"
                    class="me-2"
                    alt="icon"
                >
                Top 10 referrals
            </h4>

            <period-filter-component
                v-if="!topReferrals.isLoading"
                :props="seasons"
                name="created_at"
                @changed="onTopReferralsFiltersChanged"
                signal
                :from="topReferrals.start"
                :to="topReferrals.end"
            ></period-filter-component>
        </div>

        <template v-if="topReferrals.isLoading">
            <loader-component></loader-component>
        </template>

        <template v-else>
            <div
                v-if="topReferrals.data.length <= 0"
                class="text-center"
            >
                Referrals not found
            </div>

            <div class="blocks">
                <div
                    v-for="(value, index) in topReferrals.data"
                    :key="index"
                    class="block__line"
                >
                    <span class="block__icon block__icon_blue">
                        {{ index + 1 }}
                    </span>

                    <a
                        :href="getUserLink(value)"
                        target="_blank"
                    >
                        {{ value.first_name }}
                    </a>

                    <span></span>

                    <span class="text-end">
                        {{ value.referrals_count }} points
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import usersIcon from '@images/dashboard/dashboard_users.svg';
import captchaIcon from '@images/dashboard/dashboard_captcha.svg';
import totalReferralsIcon from '@images/dashboard/dashboard_referral.svg';
import referralsIcon from '@images/analytics/analytics_referral.svg';
import walletIcon from '@images/analytics/analytics_wallet.svg';
import bannedUsersIcon from '@images/analytics/analytics_banned_users.svg';

export default {
    name: 'AnalyticsPage',
    props: {
        seasons: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.overview.isLoading = true;
        axios.post('/analytics/overview')
            .then((result) => {
                this.overview.data = result.data;
                this.overview.isLoading = false;
            });

        this.telegramLanguages.isLoading = true;
        axios.post('/analytics/telegram-languages')
            .then((result) => {
                this.telegramLanguages.data = result.data;
                this.telegramLanguages.isLoading = false;
            });

        this.systemLanguages.isLoading = true;
        axios.post('/analytics/system-languages')
            .then((result) => {
                this.systemLanguages.data = result.data;
                this.systemLanguages.isLoading = false;
            });

        this.hourActivity.isLoading = true;
        axios.post('/analytics/hourly-activity')
            .then((result) => {
                this.hourActivity.data = result.data;
                this.hourActivity.isLoading = false;
            });

        this.usersGrowth.isLoading = true;
        axios.post('/analytics/users-growth')
            .then((result) => {
                this.usersGrowth.data = result.data;
                this.usersGrowth.isLoading = false;
            });

        this.referralsGrowth.isLoading = true;
        axios.post('/analytics/referrals-growth')
            .then((result) => {
                this.referralsGrowth.data = result.data;
                this.referralsGrowth.isLoading = false;
            });

        this.topReferrals.isLoading = true;
        axios.post('/analytics/top-referrals')
            .then((result) => {
                this.topReferrals.data = result.data;
                this.topReferrals.isLoading = false;
            });

        this.gameSessions.isLoading = true;
        axios.post('/analytics/game-sessions')
            .then((result) => {
                this.gameSessions.data = result.data;
                this.gameSessions.isLoading = false;
            });

        this.gameData.isLoading = true;
        axios.post('/analytics/game-data')
            .then((result) => {
                this.gameData.data = result.data;
                this.gameData.isLoading = false;
            });
    },
    data() {
        return {
            overview: {
                data: {},
                isLoading: false,
            },
            telegramLanguages: {
                data: {},
                isLoading: false,
            },
            systemLanguages: {
                data: {},
                isLoading: false,
            },
            hourActivity: {
                data: [],
                isLoading: false,
                start: null,
                end: null,
            },
            usersGrowth: {
                data: [],
                isLoading: false,
                start: null,
                end: null,
            },
            referralsGrowth: {
                data: [],
                isLoading: false,
                start: null,
                end: null,
            },
            topReferrals: {
                data: [],
                isLoading: false,
                start: null,
                end: null,
            },
            gameSessions: {
                data: [],
                isLoading: false,
                start: null,
                end: null,
            },
            gameData: {
                data: [],
                isLoading: false,
            },
        };
    },
    methods: {
        getOverviewIcon(key) {
            if (key === 'total_users') {
                return usersIcon;
            } else if (key === 'captcha') {
                return captchaIcon;
            } else if (key === 'total_referrals') {
                return totalReferralsIcon;
            } else if (key === 'referrals') {
                return referralsIcon;
            } else if (key === 'wallets') {
                return walletIcon;
            } else if (key === 'banned') {
                return bannedUsersIcon;
            }
            return null;
        },
        getOverviewCaption(key) {
            if (key === 'total_users') {
                return 'Total users';
            } else if (key === 'captcha') {
                return 'Users who have passed the captcha';
            } else if (key === 'total_referrals') {
                return 'Total referrals';
            } else if (key === 'referrals') {
                return 'Users who invited referrals';
            } else if (key === 'wallets') {
                return 'Total wallets';
            } else if (key === 'banned') {
                return 'Banned users';
            }
            return key;
        },
        getTelegramLanguageOptions() {
            return {
                labels: Object.keys(this.telegramLanguages.data),
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    showForSingleSeries: true,
                    position: 'bottom',
                },
            };
        },
        getTelegramLanguageSeries() {
            return Object.values(this.telegramLanguages.data);
        },
        getSystemLanguageOptions() {
            return {
                labels: Object.keys(this.systemLanguages.data),
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    showForSingleSeries: true,
                    position: 'bottom',
                },
            };
        },
        getSystemLanguageSeries() {
            return Object.values(this.systemLanguages.data);
        },
        getHourlyActivityOptions() {
            return {
                legend: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                selection: {
                    enabled: false,
                },
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: Object.keys(this.hourActivity.data),
                    labels: {
                        style: {
                            colors: Array(this.hourActivity.data.length).fill('black')
                        },
                    },

                },
                yaxis: {
                    show: false,
                }
            };
        },
        getHourlyActivitySeries() {
            return [
                {
                    name: 'Clicks',
                    data: this.hourActivity.data,
                },
            ];
        },
        getUsersGrowthOptions() {
            return {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd.MM.yyyy',
                    }
                },
            };
        },
        getUsersGrowthSeries() {
            return [
                {
                    name: 'Count',
                    data: this.usersGrowth.data,
                },
            ];
        },
        getReferralsGrowthOptions() {
            return {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd.MM.yyyy',
                    }
                },
            };
        },
        getReferralsGrowthSeries() {
            return [
                {
                    name: 'Count',
                    data: this.referralsGrowth.data,
                },
            ];
        },
        getGameSessionsOptions() {
            return {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd.MM.yyyy',
                    }
                },
            };
        },
        getGameSessionsSeries() {
            return [
                {
                    name: 'Count',
                    data: this.gameSessions.data,
                },
            ];
        },
        onHourlyActivityFiltersChanged(start, end) {
            this.hourActivity.isLoading = true;
            this.hourActivity.start = start;
            this.hourActivity.end = end;

            axios.post('/analytics/hourly-activity', {
                start: start,
                end: end,
            })
                .then((result) => {
                    this.hourActivity.data = result.data;
                    this.hourActivity.isLoading = false;
                });
        },
        onUsersGrowthFiltersChanged(start, end) {
            this.usersGrowth.isLoading = true;
            this.usersGrowth.start = start;
            this.usersGrowth.end = end;
            axios.post('/analytics/users-growth', {
                start: start,
                end: end,
            })
                .then((result) => {
                    this.usersGrowth.data = result.data;
                    this.usersGrowth.isLoading = false;
                });
        },
        onReferralsGrowthFiltersChanged(start, end) {
            this.referralsGrowth.isLoading = true;
            this.referralsGrowth.start = start;
            this.referralsGrowth.end = end;
            axios.post('/analytics/referrals-growth', {
                start: start,
                end: end,
            })
                .then((result) => {
                    this.referralsGrowth.data = result.data;
                    this.referralsGrowth.isLoading = false;
                });
        },
        onGameSessionsFiltersChanged(start, end) {
            this.gameSessions.isLoading = true;
            this.gameSessions.start = start;
            this.gameSessions.end = end;
            axios.post('/analytics/game-sessions', {
                start: start,
                end: end,
            })
                .then((result) => {
                    this.gameSessions.data = result.data;
                    this.gameSessions.isLoading = false;
                });
        },
        onTopReferralsFiltersChanged(start, end) {
            this.topReferrals.isLoading = true;
            this.topReferrals.start = start;
            this.topReferrals.end = end;
            axios.post('/analytics/top-referrals', {
                start: start,
                end: end,
            })
                .then((result) => {
                    this.topReferrals.data = result.data;
                    this.topReferrals.isLoading = false;
                });
        },
        getUserLink(user) {
            return '/telegram/users/view/' + user.uuid;
        }
    },
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.block
    position: relative
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__title_line
        display: grid
        grid-template-columns: max-content 1fr
        grid-column-gap: 10px
        align-items: center
        margin-bottom: 20px

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 34px
        height: 34px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__image
        width: 20px

    &__line
        display: grid
        grid-template-columns: max-content 1fr 60px 120px
        grid-column-gap: 10px
        align-items: center
        padding: 10px 0

    &__income
        padding: 4px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px
</style>
