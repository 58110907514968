<template>
    <telegram-text-message-component
        :index="index"
        :text="text"
    ></telegram-text-message-component>

    <check-box-form-group-component
        name="tg_units_remove_buttons_after_click"
        label="Remove button after click"
        :checked="removeButtons"
    ></check-box-form-group-component>

    <div class="mb-4">
        <div class="w-25">
            <blue-button-component
                @click="onAddRowButtonClicked"
                button
            >
                Add row
            </blue-button-component>
        </div>
    </div>

    <div
        v-for="(datum, dataIndex) in data"
        :key="dataIndex"
        class="card mb-4"
    >
        <div class="card-header d-flex">
            <div class="me-2">
                <blue-button-component
                    @click="onAddCallbackButtonClicked(dataIndex)"
                    button
                >
                    Add callback button
                </blue-button-component>
            </div>

            <div class="me-2">
                <blue-button-component
                    @click="onAddUrlButtonClicked(dataIndex)"
                    button
                >
                    Add URL button
                </blue-button-component>
            </div>

            <div class="me-2">
                <red-button-component
                    @click="onRemoveRowButtonClicked(dataIndex)"
                    button
                >
                    Remove row
                </red-button-component>
            </div>
        </div>

        <div class="card-body">
            <template
                v-for="(button, datumIndex) in datum"
                :key="dataIndex + datumIndex"
            >
                <div class="row align-items-end">
                    <div class="col-8">
                        <input-form-group-component
                            :label="getButtonLabel(dataIndex, datumIndex)"
                            :value="getButtonValue(dataIndex, datumIndex)"
                            :name="getButtonName(dataIndex, datumIndex)"
                            type="text"
                            placeholder="New button"
                        ></input-form-group-component>
                    </div>

                    <div class="col-4">
                        <div class="mb-4">
                            <red-button-component
                                @click="onRemoveButtonClicked(dataIndex, datumIndex)"
                                button
                            >
                                Remove button
                            </red-button-component>
                        </div>
                    </div>
                </div>

                <select-form-group-component
                    v-if="isCallbackButton(button)"
                    :data="getTgScreens()"
                    :name="getTgScreenName(dataIndex, datumIndex)"
                    :selected="getButtonScreenId(dataIndex, datumIndex)"
                    label="Select type"
                    @onChanged="onChangeScreen($event, dataIndex, datumIndex)"
                ></select-form-group-component>

                <input-form-group-component
                    v-else
                    :name="getButtonUrl(dataIndex, datumIndex)"
                    :value="getButtonValueUrl(dataIndex, datumIndex)"
                    label="URL"
                    type="text"
                    placeholder="https://google.com"
                ></input-form-group-component>

                <div v-if="isFormScreen(dataIndex, datumIndex)">
                    <check-box-form-group-component
                        :name="getButtonReEntryOffName(dataIndex, datumIndex)"
                            label="Disable re-entry"
                        :checked="getButtonValueReEntryOff(dataIndex, datumIndex)"
                    ></check-box-form-group-component>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import TelegramTextMessageComponent from './TelegramTextMessageComponent';

const MAX_ROWS = 10;
const MAX_BUTTONS_IN_ROW = 5;

const TYPE_SCREEN = 1;
const TYPE_FORM = 2;
const TYPE_NOT_FOUND = 3;
const TYPE_TASKS = 4;
const TYPE_WITHDRAWAL = 5;
const TYPE_WITHDRAWAL_HISTORY = 6;

export default {
    name: 'TelegramTextButtonMessageComponent',
    components: {
        TelegramTextMessageComponent,
    },
    created() {
        this.data = this.items;
        let selectedTgScreens = [];
        this.data.forEach(function (row, i) {
            selectedTgScreens[i] = [];
            row.forEach(function (cell, j) {
                selectedTgScreens[i][j] = cell.screen_id;
            })
        })
        this.selectedTgScreens = selectedTgScreens;
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        tgScreens: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            default: [],
        },
        text: {
            type: String,
            required: true,
        },
        removeButtons: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            data: [],
            selectedTgScreens: []
        };
    },
    methods: {
        onAddRowButtonClicked() {
            if (this.data.length >= MAX_ROWS) {
                return;
            }

            this.data.push([]);
        },
        onAddCallbackButtonClicked(row) {
            if (this.data[row].length >= MAX_BUTTONS_IN_ROW) {
                return;
            }

            this.data[row].push({
                text: '',
                hash: '',
                screen_id: 0,
            });
        },
        onAddUrlButtonClicked(row) {
            if (this.data[row].length >= MAX_BUTTONS_IN_ROW) {
                return;
            }

            this.data[row].push({
                text: '',
                url: '',
            });
        },
        onRemoveButtonClicked(row, column) {
            this.data[row].splice(column, 1);
        },
        onRemoveRowButtonClicked(row) {
            this.data.splice(row, 1);
        },
        getButtonLabel(row, column) {
            return 'Button ' + (row + 1) + '-' + (column + 1);
        },
        getTgScreenName(row, column) {
            return 'tg_units[' + this.index + '][buttons][' + row + '][' + column + '][callback]';
        },
        getButtonName(row, column) {
            return 'tg_units[' + this.index + '][buttons][' + row + '][' + column + '][text]';
        },
        getButtonUrl(row, column) {
            return 'tg_units[' + this.index + '][buttons][' + row + '][' + column + '][url]';
        },
        getButtonReEntryOffName(row, column) {
            return 'tg_units[' + this.index + '][buttons][' + row + '][' + column + '][data][re_entry_off]';
        },
        getButtonValue(row, column) {
            return this.data[row][column].text;
        },
        getButtonValueUrl(row, column) {
            return this.data[row][column].url;
        },
        getButtonValueReEntryOff(row, column) {
            if (this.data[row][column].hasOwnProperty('data')) {
                return this.data[row][column].data.re_entry_off || false;
            }
        },
        isCallbackButton(button) {
            return (
                button.hasOwnProperty('screen_id')
                && button.screen_id !== null
            );
        },
        getButtonScreenId(row, column) {
            const screenId = this.data[row][column].screen_id;

            if (
                screenId === null
                || screenId === 0
            ) {
                return Object.keys(this.tgScreens)[0];
            }

            return screenId;
        },
        getTgScreens() {
            let arr = {};
            let screens = this.tgScreens;

            Object.keys(screens).forEach(function (item) {
                arr[item] = screens[item].name;
            })

            return arr;
        },
        onChangeScreen(screen, row, cell) {
            if (this.selectedTgScreens[row] == null) {
                this.selectedTgScreens[row] = [];
            }
            this.selectedTgScreens[row][cell] = screen;
        },
        isFormScreen(row, cell) {
            if (this.selectedTgScreens[row] == null || this.selectedTgScreens[row][cell] == null
                || this.selectedTgScreens[row][cell] === 0) {
                return false;
            }
            return this.tgScreens[this.selectedTgScreens[row][cell]].type === TYPE_FORM;
        }
    },
}
</script>

<style lang="sass" scoped>

</style>
