<template>
    <img src="@images/utm.svg" alt="icon">
</template>

<script>
export default {
    name: 'UtmImageComponent'
}
</script>

<style scoped>

</style>
