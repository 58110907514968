<template>
    <div class="mb-4 form-check">
        <input
            :id="name"
            :name="name"
            :value="value"
            v-model="select"
            type="checkbox"
            class="form-check-input"
        >

        <label
            :for="name"
            class="form-check-label"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'CheckBoxFormGroupComponent',
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
        },
        checked: {
            type: Boolean,
        },
        value: {
            type: [String, Number],
        }
    },
    mounted() {
        this.select = this.checked;
    },
    data() {
        return {
            select: '',
        };
    },
    watch: {
        checked: {
            handler: function () {
                this.select = this.checked;
            },
        }
    }
};
</script>

<style lang="sass" scoped>
.form-check-label
    font-weight: 600
</style>
