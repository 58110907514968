<template>
    <div>
        <div class="block" v-for="session in sessions">
            <div class="block__title">
                Session id: {{ session.id }}<br>
                Session hash: {{ session.hash }}<br>
                Session IP: {{ session.ip }}<br>
                Started at: {{ (new Date(session.created_at)).toUTCString() }}<br>
                Ended at: {{ (new Date(session.updated_at)).toUTCString() }}
            </div>

            <apexchart
                v-if="session.chart_data.length > 0"
                :options="this.options"
                :series="session.chart_data"
                type="area"
            ></apexchart>
        </div>

        <div class="block">
            <slot name="pagination"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserGameStatsPage',
    props: {
        sessions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            options: {},
        };
    }
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.block
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 40px
        height: 40px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__image
        width: 20px

    &__line
        display: grid
        grid-template-columns: max-content 1fr max-content max-content
        grid-column-gap: 10px
        align-items: center
        padding: 10px 0

    &__income
        padding: 4px 8px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px
</style>
