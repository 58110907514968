<template>
    <button
        :type="getType()"
        class="button"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'ButtonComponent',
    props: {
        button: {
            type: Boolean,
            default: false,
        },
        submit: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getType() {
            if (this.button) {
                return 'button';
            }

            return 'submit';
        },
    },
};
</script>

<style lang="sass" scoped>
.button
    display: block
    width: 100%
    padding: 10px 40px
    color: #fff
    font-size: 16px
    font-weight: 600
    border: 0
    border-radius: 4px
</style>
