import './bootstrap';

import {createApp} from 'vue';
import {i18nVue} from 'laravel-vue-i18n';
import VueApexCharts from 'vue3-apexcharts';

// Components
import LogoComponent from './Components/LogoComponent';
import BlockComponent from './Components/BlockComponent';
import TableComponent from './Components/TableComponent';
import WebAppComponent from './Components/WebAppComponent';
import PageTitleComponent from './Components/PageTitleComponent';
import NavigationComponent from './Components/NavigationComponent';
import LoaderComponent from './Components/LoaderComponent';
import PaginationComponent from './Components/PaginationComponent';
import ModuleBlockComponent from './Components/ModuleBlockComponent';

// Editor
import CustomEditorComponent from './Components/Editor/CustomEditorComponent';

// Images
import SeasonImageComponent from './Components/Images/SeasonImageComponent';
import UtmImageComponent from './Components/Images/UtmImageComponent';

// Buttons
import GreenButtonComponent from './Components/Buttons/GreenButtonComponent';
import BlueButtonComponent from './Components/Buttons/BlueButtonComponent';
import RedButtonComponent from './Components/Buttons/RedButtonComponent';
import WhiteButtonComponent from './Components/Buttons/WhiteButtonComponent';

// FormGroups
import InputFormGroupComponent from './Components/FormGroups/InputFormGroupComponent';
import CheckBoxFormGroupComponent from './Components/FormGroups/CheckBoxFormGroupComponent';
import SelectFormGroupComponent from './Components/FormGroups/SelectFormGroupComponent';
import TextAreaFormGroupComponent from './Components/FormGroups/TextAreaFormGroupComponent';

// Telegram
import TelegramMessagesComponent from './Components/Telegram/TelegramMessagesComponent';
import TelegramRoutesComponent from './Components/Telegram/Routes/TelegramRoutesComponent';
import TelegramFormsComponent from './Components/Telegram/Forms/TelegramFormsComponent';
import TelegramFiltersComponent from './Components/Telegram/TelegramFiltersComponent';

// captcha
import CaptchaComponent from './Components/Captcha/CaptchaComponent';

// Filters
import PeriodFilterComponent from './Components/Filters/PeriodFilterComponent';
import FilterComponent from './Components/Filters/FilterComponent';

// Tasks
import CompletedTasksComponent from "./Components/Tasks/CompletedTasksComponent.vue";
import CompletedTasksStatisticsComponent from "./Components/Tasks/CompletedTasksStatisticsComponent.vue";
import CompletedTasksGrowthComponent from "./Components/Tasks/CompletedTasksGrowthComponent.vue";

// Reports
import ReportTypeComponent from './Components/Reports/ReportTypeComponent';

// Pages
import TelegramUserViewPage from './Pages/Telegram/Users/TelegramUserViewPage';
import TaskUnitViewPage from './Pages/Tasks/TaskUnitViewPage';
import UtmViewPage from './Pages/Utm/UtmViewPage';
import DashboardPage from './Pages/Dashboard/DashboardPage';
import AnalyticsPage from './Pages/Analytics/AnalyticsPage';
import UserGameStatsPage from './Pages/Telegram/Users/UserGameStatsPage';
import TelegramMenuViewPage from './Pages/Telegram/Menu/TelegramMenuViewPage';
import WithdrawalViewPage from './Pages/Withdrawal/WithdrawalViewPage';
import TaskAnalyticsPage from './Pages/Tasks/TaskAnalyticsPage';
import OverviewTaskAnalyticsPage from './Pages/Analytics/TaskAnalyticsPage';
import UserGameSessionsPage from './Pages/Telegram/Users/UserGameSessionsPage';

// Page blocks
import MailingBlockComponent from './Pages/Mailing/MailingBlockComponent';

// Creatives
import CreativeViewPage from "./Pages/Creatives/CreativeViewPage.vue";

const app = createApp({});

app
    // components
    .component('logo-component', LogoComponent)
    .component('block-component', BlockComponent)
    .component('table-component', TableComponent)
    .component('web-app-component', WebAppComponent)
    .component('page-title-component', PageTitleComponent)
    .component('navigation-component', NavigationComponent)
    .component('custom-editor-component', CustomEditorComponent)
    .component('loader-component', LoaderComponent)
    .component('pagination-component', PaginationComponent)
    .component('module-block-component', ModuleBlockComponent)

    // images
    .component('season-image-component', SeasonImageComponent)
    .component('utm-image-component', UtmImageComponent)

    // buttons
    .component('green-button-component', GreenButtonComponent)
    .component('blue-button-component', BlueButtonComponent)
    .component('red-button-component', RedButtonComponent)
    .component('white-button-component', WhiteButtonComponent)

    // FormGroups
    .component('check-box-form-group-component', CheckBoxFormGroupComponent)
    .component('input-form-group-component', InputFormGroupComponent)
    .component('select-form-group-component', SelectFormGroupComponent)
    .component('text-area-form-group-component', TextAreaFormGroupComponent)

    // captcha
    .component('captcha-component', CaptchaComponent)

    // telegram
    .component('telegram-messages-component', TelegramMessagesComponent)
    .component('telegram-routes-component', TelegramRoutesComponent)
    .component('telegram-forms-component', TelegramFormsComponent)
    .component('telegram-filters-component', TelegramFiltersComponent)

    // filters
    .component('period-filter-component', PeriodFilterComponent)
    .component('filter-component', FilterComponent)

    // tasks
    .component('completed-tasks-component', CompletedTasksComponent)
    .component('completed-tasks-statistics-component', CompletedTasksStatisticsComponent)
    .component('completed-tasks-growth-component', CompletedTasksGrowthComponent)

    .component('report-type-component', ReportTypeComponent)

    // pages
    .component('telegram-user-view-page', TelegramUserViewPage)
    .component('utm-view-page', UtmViewPage)
    .component('task-unit-view-page', TaskUnitViewPage)
    .component('dashboard-page', DashboardPage)
    .component('analytics-page', AnalyticsPage)
    .component('user-game-stats-page', UserGameStatsPage)
    .component('telegram-menu-view-page', TelegramMenuViewPage)
    .component('withdrawal-view-page', WithdrawalViewPage)
    .component('task-analytics-view-page', TaskAnalyticsPage)
    .component('overview-task-analytics-view-page', OverviewTaskAnalyticsPage)
    .component('user-game-sessions-page', UserGameSessionsPage)

    // creative
    .component('creative-view-page', CreativeViewPage)

    // page blocks
    .component('mailing-block-component', MailingBlockComponent)
;

app.use(i18nVue, {
    resolve: lang => import(`../../lang/${lang}.json`),
});

app.use(VueApexCharts);

app.mount('#app');
