<template>
    <div
        v-if="isShow"
        class="row align-items-end"
    >
        <div class="col-8">
            <input-form-group-component
                :name="getName()"
                :value="getValue()"
                label="Command"
                type="text"
                placeholder="/start"
                required
            ></input-form-group-component>
        </div>

        <div class="col-4">
            <div class="mb-4">
                <red-button-component
                    @click="onRemoveClicked"
                    button
                >
                    Remove command
                </red-button-component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TelegramRouteComponent',
    created() {
        this.route = this.item;
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        item: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            isShow: true,
            route: {},
        };
    },
    methods: {
        onRemoveClicked() {
            this.isShow = false;
        },
        getName() {
            return 'tg_routes[' + this.index + '][command]';
        },
        getValue() {
            if (this.route.hasOwnProperty('command')) {
                return this.route.command;
            }

            return '';
        },
    },
}
</script>

<style scoped>

</style>
