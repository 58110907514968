<template>
    <nav class="navigation">
        <template
            v-for="(link, index) in links"
            :key="index"
        >
            <a
                v-if="link.isVisible && isUrl(link)"
                :href="link.url"
                :class="getClass(link.url)"
            >
                <img
                    v-if="link.icon !== null"
                    :src="getIcon(link)"
                    alt="icon"
                >

                {{ link.caption }}
            </a>

            <template v-else-if="!isUrl(link) && link.isVisible">
                <a
                    :data-bs-target="getTarget(index)"
                    class="navigation__link collapsed"
                    data-bs-toggle="collapse"
                >
                    <img
                        v-if="link.icon !== null"
                        :src="getIcon(link)"
                        alt="icon"
                    >

                    {{ link.caption }}

                    <img class="navigation__arrow" src="@images/menu/arrow.svg" alt="arrow">
                </a>

                <div
                    :id="getId(index)"
                    class="navigation__links collapse"
                >
                    <a
                        v-for="(children, childrenIndex) in link.children.filter(item => item.isVisible)"
                        :class="getClass(children.url)"
                        :key="childrenIndex"
                        :href="children.url"
                    >
                        {{ children.caption }}
                    </a>
                </div>
            </template>
        </template>
    </nav>
</template>

<script>
import dashboardIcon from '@images/menu/dashboard.svg';
import taskIcon from '@images/menu/task.svg';
import topUsersIcon from '@images/menu/top_users.svg';
import modulesIcon from '@images/menu/modules.svg';
import marketingIcon from '@images/menu/marketing.svg';
import settingsIcon from '@images/menu/settings_menu.svg';
import pricesIcon from '@images/menu/prices.svg';
import logoutIcon from '@images/menu/logout.svg';
import rootIcon from '@images/menu/root.svg';

export default {
    name: 'NavigationComponent',
    props: {
        links: {
            type: Object,
            required: true,
        },
        current: {
            type: String,
            default: '',
        },
    },
    methods: {
        getClass(url) {
            let result = 'navigation__link';

            if (url === this.current) {
                result += ' navigation__link_active';
            }

            return result;
        },
        isUrl(link) {
            return (
                link.hasOwnProperty('url')
                && link.url !== null
            );
        },
        getTarget(index) {
            return '#navigation-item-' + index;
        },
        getId(index) {
            return 'navigation-item-' + index;
        },
        getIcon(link) {
            if (link.icon === 'dashboard') {
                return dashboardIcon;
            } else if(link.icon === 'task') {
                return taskIcon;
            } else if (link.icon === 'top_users') {
                return topUsersIcon;
            } else if (link.icon === 'modules') {
                return modulesIcon;
            } else if (link.icon === 'marketing') {
                return marketingIcon;
            } else if (link.icon === 'settings') {
                return settingsIcon;
            } else if (link.icon === 'prices') {
                return pricesIcon;
            } else if (link.icon === 'logout') {
                return logoutIcon;
            } else if (link.icon === 'root') {
                return rootIcon;
            }
        },
    },
}
</script>

<style lang="sass" scoped>
.navigation
    font-size: 16px

    &__arrow
        transition: transform .2s ease-in-out
        transform: rotate(180deg)

    &__links
        .navigation__link
            padding-left: 54px
            color: #93BFFF

    &__link
        display: grid
        grid-template-columns: max-content 1fr max-content
        grid-column-gap: 10px
        align-items: center
        padding: 10px 30px
        color: #fff
        text-decoration: none
        transition: all 200ms linear

        &_active
            background-color: #253693

        &:hover
            background-color: #253693
            cursor: pointer

        &.collapsed > .navigation__arrow
            transform: rotate(0deg)

</style>
