<template>
    <div class="block">
        <div class="block__title_line">
            <h4 class="block__title">
                <img
                    src="@images/analytics/analytics_chart.svg"
                    class="me-2"
                    alt="icon"
                >
                Completed tasks growth
            </h4>

            <div class="block__filters">
                <filter-component
                    :props="propFilters"
                    class="me-2"
                    label="Filter by tasks"
                    @changed="onChangeFilters"
                ></filter-component>

                <period-filter-component
                    :props="seasons"
                    :from="start"
                    :to="end"
                    name="created_at"
                    @changed="onChangePeriodFilters"
                    signal
                ></period-filter-component>
            </div>
        </div>

        <div v-if="isLoading">
            <loader-component></loader-component>
        </div>

        <div v-else>
            <apexchart
                :options="getCompletedTasksGrowthOptions()"
                :series="getCompletedTasksGrowthSeries()"
                :height="400"
                type="area"
            ></apexchart>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CompletedTasksGrowthComponent',
    props: {
        task: {
            type: Object,
            default: null
        },
        propFilters: {
            type: Array,
            default: []
        },
        seasons: {
            type: Object,
            required: true,
        },
        hasTaskModule: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            data: [],
            filters: [],
            start: null,
            end: null,
        }
    },
    mounted() {
        this.uploadData();
    },
    methods: {
        async uploadData() {
            if (!this.hasTaskModule) {
                return;
            }
            this.isLoading = true;
            let filters = this.filters;
            axios.post('/analytics/completed-tasks-growth', {
                filters: filters,
                start: this.start,
                end: this.end,
            }).then((result) => {
                this.data = result.data;
                this.isLoading = false;
            });
        },
        getCompletedTasksGrowthOptions() {
            return {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd.MM.yyyy',
                    }
                },
            };
        },
        getCompletedTasksGrowthSeries() {
            return [
                {
                    name: 'Count',
                    data: this.data,
                },
            ];
        },
        onChangeFilters(filters) {
            this.filters = filters;
            this.uploadData();
        },
        onChangePeriodFilters(start, end) {
            this.start = start;
            this.end = end;
            this.uploadData();
        }
    }
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.striped-row
    background-color: #F8F8F9 !important

.block
    position: relative
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title_line
        display: grid
        grid-template-columns: max-content 1fr
        grid-column-gap: 10px
        align-items: center
        margin-bottom: 20px

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 40px
        height: 40px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__line
        display: grid
        grid-template-columns: max-content 1fr 60px 60px
        grid-column-gap: 10px
        align-items: center
        padding: 10px 0

    &__income
        padding: 4px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px

    &__filters
        display: flex
        justify-content: flex-end
</style>
