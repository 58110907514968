<template>
    <TelegramImageMessageComponent></TelegramImageMessageComponent>
    <TelegramTextMessageComponent></TelegramTextMessageComponent>
</template>

<script>
import TelegramTextMessageComponent from './TelegramTextMessageComponent';
import TelegramImageMessageComponent from './TelegramImageMessageComponent';

export default {
    name: 'TelegramImageTextMessageComponent',
    components: {
        TelegramImageMessageComponent,
        TelegramTextMessageComponent,
    }
};
</script>

<style lang="sass" scoped>

</style>
