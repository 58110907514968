<template>
    <div class="mb-4">
        <label
            v-if="label"
            :for="name"
            class="form-label"
        >
            {{ label }}
        </label>

        <select
            :name="name"
            :class="inputClass"
            :disabled="disabled"
            :required="required"
            v-model="select"
            @change="onChanged($event)"
        >
            <option selected disabled hidden value="">
                {{ label }}
            </option>

            <option
                v-for="(datum, index) in data"
                :key="index"
                :value="getValue(index)"
                :selected="true"
            >
                {{ datum }}
            </option>
        </select>

        <div
            v-if="error"
            class="invalid-feedback"
            v-html="getError()"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'SelectFormGroupComponent',
    props: {
        data: {
            type: Object,
            default: [],
        },
        label: {
            type: String,
            required: true,
        },
        selected: {
            type: [String, Number],
            default: '',
        },
        disabled: {
            type: Boolean
        },
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
        },
        required: {
            type: Boolean,
        },
    },
    mounted() {
        this.select = this.selected;
    },
    data() {
        return {
            select: '',
            inputClass: {
                'form-select': true,
                'is-invalid': this.error,
            },
        };
    },
    methods: {
        getValue(index) {
            const keys = Object.keys(this.data);
            return keys.find((element) => element === index);
        },
        isSelected(index) {
            return this.getValue(index) === this.selected;
        },
        onChanged(event) {
            this.$emit('onChanged', event.target.value);
        },
        getError() {
            if (Array.isArray(this.error)) {
                return this.error.join('<br>');
            }

            return this.error;
        }
    },
    watch: {
        selected: {
            handler: function () {
                this.select = this.selected;
            }
        }
    }
};
</script>

<style lang="sass" scoped>
.form-label
    font-weight: 600
</style>
