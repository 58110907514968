<template>
    <select-form-group-component
        :data='getTypes()'
        label="Multiple choice answer"
        name="unit_multiple_answer[]"
        required
    ></select-form-group-component>

    <text-area-form-group-component
        label="Question"
        name="unit_text[]"
        placeholder="Who can do it?"
        rows="10"
        required
    ></text-area-form-group-component>

    <input-form-group-component
        v-for="index in 5"
        :key="index"
        :index="index"
        :label="'Answer ' + index"
        :placeholder="'Answer ' + index"
        type="text"
        required
    ></input-form-group-component>

    <input
        :value="getAnswers()"
        type="hidden"
        name="unit_answers[]"
    >
</template>

<script>
export default {
    name: 'TelegramPollMessageComponent',
    props: {
        index: {
            type: Number,
            required: true,
        },
        answers: {
            type: Array,
        },
    },
    methods: {
        getTypes() {
            return {
                1: 'No',
                2: 'Yes',
            };
        },
        getAnswers() {
            return this.answers;
        },
    },
};
</script>

<style lang="sass" scoped>

</style>
