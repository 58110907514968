<template>
    <div class="d-flex justify-content-end align-items-center">
        <button
            class="settings"
            type="button"
            data-bs-toggle="dropdown"
            v-bind:class="{
                'settings-disabled': disabled
            }"
            :disabled="disabled"
        >
            Filters
        </button>

        <div class="dropdown-menu overflow-auto" @click="clickOnDropdownMenu">
            <h4 v-if="label">
                {{ label }}:
            </h4>

            <button
                v-for="filter in filters"
                @click="onFilterClicked(filter)"
                class="filter"
                type="button"
                v-bind:class="{
                    'filter__active': filter.is_checked
                }"
            >
                {{ filter.caption }}
            </button>

            <div class="filter-buttons d-flex justify-content-between align-items-center">
                <white-button-component
                    @click="onResetClicked"
                    button
                >
                    Clear
                </white-button-component>
                <blue-button-component
                    @click="onSetClicked"
                    button
                >
                    Set
                </blue-button-component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterComponent',
    emits: [
        'changed',
    ],
    props: {
        label: {
            type: String
        },
        props: {
            type: Object,
            default: {},
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filters: []
        };
    },
    mounted() {
        this.filters = this.props;
        this.onResetClicked();
    },
    methods: {
        clickOnDropdownMenu(e) {
            e.stopPropagation();
        },
        onFilterClicked(filter) {
            filter.is_checked = !filter.is_checked;
        },
        onResetClicked() {
            this.filters.map((filter) => filter.is_checked = false);
        },
        onSetClicked() {
            this.$emit('changed', this.filters.filter((filter) => filter.is_checked === true));
        },
    },
}
</script>

<style lang="sass" scoped>
.settings
    width: 120px
    height: 34px
    background-color: #0091FF
    background-image: url('@images/filters.svg')
    background-position: 18%, center
    background-repeat: no-repeat
    border: 0
    border-radius: 5px
    font-weight: 700
    font-size: 14px
    color: #ffffff
.settings-disabled
    opacity: 0.3

.dropdown-menu
    padding: 10px
    border: 0
    box-shadow: 0 0 40px #EEEEEE, 0 4px 8px #42474C0D
    max-height: 500px

.filter
    display: block
    width: 100%
    margin-bottom: 6px
    padding: 6px 12px
    background-color: #F5F9FE
    font-weight: 500
    border: 0
    border-radius: 5px

    &:hover
        background-color: #0091FF
        color: #fff

    &__active
        background-color: #0091FF
        color: #fff

.apply
    padding: 6px 12px
    background-color: #0091FF
    color: #fff
    border: 0
    border-radius: 5px

.filter-buttons > .button:not(:last-child)
    margin-right: 20px

</style>
