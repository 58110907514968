<template>
    <select-form-group-component
        :data="selectData"
        @on-changed="onTypeChanged"
        label="Select type"
        name="type_id"
        required
    ></select-form-group-component>

    <select-form-group-component
        v-if="type === 2"
        :data="parsedSeasons"
        label="Season"
        name="season_id"
    ></select-form-group-component>
</template>

<script>
export default {
    name: 'ReportTypeComponent',
    props: {
        selectData: {
            type: Object,
            required: true,
        },
        seasons: {
            type: Object,
            required: false,
        },
    },
    mounted() {
        const seasons = {};
        this.seasons.map(function(rawSeason) {
            seasons[rawSeason.id] = rawSeason.caption;
        });
        this.parsedSeasons = seasons;
    },
    data() {
        return {
            type: 1,
            parsedSeasons: {},
        };
    },
    methods: {
        onTypeChanged(type) {
            this.type = parseInt(type);
        },
    },
}
</script>

<style scoped>

</style>
