<template>
    <div class="mb-4">
        <div class="w-25">
            <blue-button-component
                @click="onAddButtonClicked"
                button
            >
                Add command
            </blue-button-component>
        </div>
    </div>

    <telegram-route-component
        v-for="(tgRoute, index) in tgRoutes"
        :key="index"
        :index="index"
        :item="this.items[index]"
    ></telegram-route-component>
</template>

<script>
import TelegramRouteComponent from './TelegramRouteComponent';

export default {
    name: 'TelegramRoutesComponent',
    components: {
        TelegramRouteComponent,
    },
    created() {
        this.tgRoutes = this.items;
    },
    props: {
        items: Array,
        default: [],
    },
    data() {
        return {
            tgRoutes: [],
            deletedIds: [],
        };
    },
    methods: {
        onAddButtonClicked() {
            this.tgRoutes.push({});
        },
        onDeleted(id) {
            this.deletedIds.push(id);
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
