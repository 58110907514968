// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/settings.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".settings[data-v-d6e40164]{background-color:#0091ff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;border:0;border-radius:5px;height:34px;width:34px}.dropdown-menu[data-v-d6e40164]{border:0;box-shadow:0 0 40px #eee,0 4px 8px rgba(66,71,76,.051);padding:10px}.period[data-v-d6e40164]{background-color:#f5f9fe;border:0;border-radius:5px;display:block;font-weight:500;margin-bottom:6px;padding:6px 12px;width:100%}.apply[data-v-d6e40164],.period[data-v-d6e40164]:hover{background-color:#0091ff;color:#fff}.apply[data-v-d6e40164]{border:0;border-radius:5px;padding:6px 12px}", "",{"version":3,"sources":["webpack://./resources/js/Components/Filters/PeriodFilterComponent.vue"],"names":[],"mappings":"AACA,2BAGI,wBAAA,CACA,wDAAA,CACA,uBAAA,CACA,2BAAA,CACA,QAAA,CACA,iBAAA,CANA,WAAA,CADA,UAOJ,CAEA,gCAEI,QAAA,CACA,sDAAA,CAFA,YAGJ,CACA,yBAKI,wBAAA,CAEA,QAAA,CACA,iBAAA,CAPA,aAAA,CAKA,eAAA,CAHA,iBAAA,CACA,gBAAA,CAFA,UAQJ,CAIA,uDAHQ,wBAAA,CACA,UAUR,CARA,wBAII,QAAA,CACA,iBAAA,CAJA,gBAOJ","sourcesContent":["\n.settings\n    width: 34px\n    height: 34px\n    background-color: #0091FF\n    background-image: url('@images/settings.svg')\n    background-position: center\n    background-repeat: no-repeat\n    border: 0\n    border-radius: 5px\n\n.dropdown-menu\n    padding: 10px\n    border: 0\n    box-shadow: 0 0 40px #EEEEEE, 0 4px 8px #42474C0D\n\n.period\n    display: block\n    width: 100%\n    margin-bottom: 6px\n    padding: 6px 12px\n    background-color: #F5F9FE\n    font-weight: 500\n    border: 0\n    border-radius: 5px\n\n    &:hover\n        background-color: #0091FF\n        color: #fff\n\n.apply\n    padding: 6px 12px\n    background-color: #0091FF\n    color: #fff\n    border: 0\n    border-radius: 5px\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
