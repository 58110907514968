<template>
    <div class="mb-4">
        <label
            v-if="label"
            :for="name"
            class="form-label"
        >
            {{ label }}
        </label>

        <input
            :required="required"
            :value="inputValue"
            :name="name"
            :placeholder="placeholder"
            :class="inputClass"
            :style="{ ...styles }"
            :type="type"
            :id="name"
            :min="min"
            :max="max"
            :minlength=minlength
            :maxlength=maxlength
            :accept="accept"
            :readonly="readonly"
            :step="step"
            @input="onInput"
        />

        <div
            v-if="error"
            class="invalid-feedback"
            v-html="getError()"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'InputFormGroupComponent',
    props: {
        value: {
            type: [String, Number],
        },
        name: {
            type: String,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        error: {
            type: [String, Array],
        },
        styles: {
            type: Object,
        },
        tooltip: {
            type: String,
        },
        type: {
            type: String,
            default: 'text'
        },
        readonly: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        min: {
            type: [Number,String],
        },
        max: {
            type: [Number,String],
        },
        minlength: {
            type: [Number,String],
        },
        maxlength: {
            type: [Number,String],
        },
        accept: {
            type: String,
        },
        step: {
            type: [Number, String],
        },
    },
    mounted() {
        this.errorData = this.error;
        this.inputValue = this.value;
    },
    data() {
        return {
            errorData: null,
            inputValue: ''
        };
    },
    methods: {
        onInput(event) {
            this.inputValue = event.target.value;
            this.$emit('input', event.target.value);
        },
        getError() {
            if (Array.isArray(this.error)) {
                return this.error.join('<br>');
            }

            return this.error;
        }
    },
    watch: {
        error: {
            handler: function (newValue) {
                this.errorData = newValue;
            }
        },
        value: {
            handler: function (newValue) {
                this.inputValue = newValue;
            }
        }
    },
    computed: {
        inputClass: function () {
            return {
                'form-control': true,
                'is-invalid': this.errorData,
            }
        }
    }
};
</script>

<style lang="sass" scoped>
.form-label
    font-weight: 600
</style>
