<template>
    <div>
        <module-block-component
            module="Tasks"
            :has-module="hasTaskModule"
        >
            <completed-tasks-growth-component
                :seasons="seasons"
                :prop-filters="filters"
                :task="task"
                :has-task-module="hasTaskModule"
            ></completed-tasks-growth-component>
        </module-block-component>

        <completed-tasks-statistics-component
            :task="task"
            :has-task-module="hasTaskModule"
        ></completed-tasks-statistics-component>

        <completed-tasks-component
            :task="task"
            :filters-for-completed="filters"
            :filters-for-checking="filters"
            :has-task-module="hasTaskModule"
        ></completed-tasks-component>
    </div>
</template>

<script>
export default {
    name: 'TaskAnalyticsPage',
    props: {
        task: {
            type: Object,
            required: true,
        },
        filters: {
            type: Array,
            default: []
        },
        seasons: {
            type: Object,
            required: true,
        },
        hasTaskModule: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.block
    position: relative
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 40px
        height: 40px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__image
        width: 20px

    &__line
        display: grid
        grid-template-columns: max-content 1fr 60px 60px
        grid-column-gap: 10px
        align-items: center
        padding: 10px 0

    &__income
        padding: 4px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px
</style>
