<template>
    <button-component
        :button="button"
        :submit="submit"
        @click="onClicked"
    >
        <slot></slot>
    </button-component>
</template>

<script>
import ButtonComponent from './ButtonComponent';

export default {
    name: 'RedButtonComponent',
    components: {
        ButtonComponent,
    },
    props: {
        link: {
            type: String,
            default: '',
        },
        button: {
            type: Boolean,
            default: false,
        },
        submit: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClicked() {
            if (this.link) {
                window.location.href = this.link;
            }
        },
    },
};
</script>

<style lang="sass" scoped>
.button
    background-color: #FF002E
</style>
