<template>
    <div
        v-if="hasHeader()"
        class="header row"
    >
        <div class="col-6">
            <template v-if="hasSearch()">
                <form class="search" method="get" v-if="searchForm">
                    <input
                        :placeholder="searchPlaceholder"
                        class="form-control search__input"
                        :name="searchName"
                        type="text"
                    >

                    <div class="search__button">
                        <blue-button-component>
                            Search
                        </blue-button-component>
                    </div>
                </form>
                <form class="search" v-else v-on:submit.prevent="onChangeSearch">
                    <input
                        :placeholder="searchPlaceholder"
                        class="form-control search__input"
                        :name="searchName"
                        type="text"
                        v-model="searchString"
                    >

                    <div class="search__button">
                        <blue-button-component
                            @click="onChangeSearch"
                        >
                            Search
                        </blue-button-component>
                    </div>
                </form>
            </template>
        </div>

        <div class="col-6 text-end">
            <template v-if="hasFiltersSlot()">
                <form method="get">
                    <slot name="filters"></slot>
                </form>
            </template>
        </div>
    </div>

    <div v-if="getData().length > 0">
        <table>
            <thead>
            <tr>
                <th>
                    #
                </th>

                <th
                    v-for="(column, index) in columns"
                    :key="index"
                >
                    {{ column }}
                </th>

                <th v-if="hasDeleteSlot()"></th>
                <th v-if="hasViewSlot()"></th>
                <th v-if="hasCustomButton()"></th>
            </tr>
            </thead>

            <tbody>
            <template
                v-for="(datum, index) in getData()"
                :key="index"
            >
                <tr v-bind:class="{
                    'striped-row': index % 2 !== 0,
                    'row-opened': isOpened(datum)
                }">
                    <td>
                        {{ getNumber(index) }}
                    </td>

                    <td
                        v-for="(property, index2) in attributes"
                        :key="index2"
                    >
                        {{ getDatum(property, datum) }}
                    </td>

                    <td
                        v-if="hasDeleteSlot()"
                        class="text-center"
                        style="max-width: 1px"
                    >
                        <a
                            class="delete"
                            @click="onDeleteClicked(datum)"
                        ></a>
                    </td>

                    <td
                        v-if="hasViewSlot()"
                        class="text-center"
                        style="max-width: 1px"
                    >
                        <a
                            :href="getDatum('table_url_view', datum)"
                            class="view"
                        ></a>
                    </td>

                    <td v-if="hasCustomButton()">
                        <slot name="custom" :item="datum"></slot>
                    </td>

                    <td
                        v-if="hasOpenSlot()"
                        class="text-center"
                        style="max-width: 1px"
                    >
                        <a
                            v-if="isOpened(datum)"
                            @click="changeOpen(datum)"
                            class="close"
                        ></a>

                        <a
                            v-else
                            @click="changeOpen(datum)"
                            class="open"
                        ></a>
                    </td>
                </tr>

                <template
                    v-if="subRow"
                >
                    <TransitionGroup>
                        <tr
                            v-if="isOpened(datum)"
                            v-for="(subDatum, subIndex) in getDatum(subRow, datum)"
                            :key="'sub'+subIndex"
                            v-bind:class="{
                                'striped-row': index % 2 !== 0
                            }" class="sub-row"
                        >
                            <td>
                            </td>

                            <td
                                v-for="(property, index2) in subAttributes"
                                :key="index2"
                            >
                                {{ getDatum(property, subDatum) }}
                            </td>

                            <td>
                            </td>

                            <td
                                v-if="hasDeleteSlot()"
                                class="text-center"
                                style="max-width: 1px"
                            >
                            </td>

                            <td
                                v-if="hasViewSlot()"
                                class="text-center"
                                style="max-width: 1px"
                            >
                            </td>
                            <td v-if="hasCustomButton()" style="max-width: 100px">
                            </td>
                        </tr>
                    </TransitionGroup>
                </template>
            </template>
            </tbody>
        </table>

        <div
            v-if="data.hasOwnProperty('total')"
            class="total"
        >
            Total records: <span class="fw-bold">{{ data.total }}</span>
        </div>

        <div v-if="hasPaginationSlot()">
            <slot name="pagination"></slot>
        </div>
    </div>

    <h2 v-else>
        Nothing found
    </h2>
</template>

<script>
export default {
    name: 'TableComponent',
    emits: ['changeSearch'],
    props: {
        data: {
            type: [Array, Object],
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        attributes: {
            type: Array,
            required: true,
        },
        view: {
            type: Boolean,
            default: false,
        },
        search: {
            type: Boolean,
            default: false,
        },
        delete: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: 'Search...',
        },
        searchName: {
            type: String,
            default: 'search',
        },
        subRow: {
            type: String,
            default: null
        },
        subAttributes: {
            type: Array,
            default: []
        },
        searchForm: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tableData: [],
            show: true,
            searchString: ''
        }
    },
    mounted() {
        this.setData(this.data);
    },
    methods: {
        setData(data) {
            this.tableData = (
                this.data.hasOwnProperty('data')
                && Array.isArray(data.data)
            )
                ? data.data
                : data;
        },
        getData() {
            return this.tableData;
        },
        getDatum(property, datum) {
            const propertyParse = property.split('.');

            if (!datum) {
                return '-';
            }

            if (propertyParse.length === 1) {
                if (!datum.hasOwnProperty(property)) {
                    return '-';
                } else {
                    return datum[property];
                }
            }

            datum = datum[propertyParse[0]];

            propertyParse.shift();

            property = propertyParse.join('.');

            return this.getDatum(
                property,
                datum,
            );
        },
        hasViewSlot() {
            return this.view;
        },
        hasDeleteSlot() {
            return this.delete;
        },
        hasCustomButton() {
            return this.$slots.hasOwnProperty('custom');
        },
        hasPaginationSlot() {
            return this.$slots.hasOwnProperty('pagination');
        },
        hasHeader() {
            return (
                this.search
                || this.hasFiltersSlot()
            );
        },
        hasSearch() {
            return this.search;
        },
        hasFiltersSlot() {
            return this.$slots.hasOwnProperty('filters');
        },
        getNumber(index) {
            if (!this.data.hasOwnProperty('per_page')) {
                return index + 1;
            }

            const number = ((this.data.current_page - 1) * this.data.per_page) + index + 1;

            return number.toString().padStart(2, '0');
        },
        onDeleteClicked(datum) {
            const url = this.getDatum('table_url_delete', datum);

            let result = confirm('Do you want to delete?');

            if (result) {
                window.location.href = url;
            }
        },
        hasOpenSlot() {
            return this.subRow !== null;
        },
        changeOpen(datum) {
            datum.is_opened = !datum.is_opened;
        },
        isOpened(datum) {
            if (!datum.hasOwnProperty('is_opened')) {
                datum.is_opened = false;
            }

            return datum.is_opened;
        },
        onChangeSearch() {
            this.$emit('changeSearch', this.searchString);
        },
    },
    watch: {
        data: {
            handler: function (newValue) {
                this.setData(newValue);
            }
        }
    },
}
</script>

<style lang="sass" scoped>
.header
    margin-bottom: 30px

.search
    display: grid
    grid-template-columns: 1fr max-content

    &__input
        padding-left: 40px
        background-image: url('@images/search.svg')
        background-repeat: no-repeat
        background-position: 15px center

    &__button
        position: relative
        left: -5px

.total
    margin: 20px 0

.view
    display: block
    width: 30px
    height: 30px
    background-color: #0091FF
    background-image: url('@images/chevron_right.svg')
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%

    &:hover
        background-color: #2975af

.delete
    display: block
    width: 30px
    height: 30px
    background-color: #94A3B8
    background-image: url('@images/delete.svg')
    background-position: center
    background-size: 60%
    background-repeat: no-repeat
    border-radius: 50%

    &:hover
        background-color: #757f8c

.open
    display: block
    width: 30px
    height: 30px
    background-color: #0091FF
    background-image: url('@images/chevron_down.svg')
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%
    cursor: pointer

    &:hover
        background-color: #2975af

.close
    display: block
    width: 30px
    height: 30px
    background-color: #0091FF
    background-image: url('@images/chevron_up.svg')
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%
    cursor: pointer

    &:hover
        background-color: #2975af

.striped-row
    background-color: #F8F8F9 !important

.row-opened
    border-bottom: 1px solid #E6EAF0

.v-enter-active, .v-leave-active
    transition: opacity 0.5s ease

.v-enter-from, .v-leave-to
    opacity: 0

.v-enter-to
    opacity: 1

</style>
