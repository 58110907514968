<template>
    <div
        class="block"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'WebAppComponent'
};
</script>

<style lang="sass" scoped>
.block
    margin-bottom: 20px
    padding: 40px
    background-color: var(--tg-theme-bg-color)
    border-radius: 10px

    &_shadow
        box-shadow: 15px 15px 35px rgb(0, 0, 0, 0.2)

    &__title
        margin-bottom: 20px
        color: #0c3979
        font-size: 24px
        font-weight: 600
</style>
