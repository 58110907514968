<template>
    <h1 class="page-title">
        <slot></slot>
    </h1>
</template>

<script>
export default {
    name: 'PageTitleComponent',
    props: {
        icon: {
            type: String,
            default: '',
        },
        verified: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getSrc() {
            return '@images/pages/' + this.icon + '.svg';
        },
    },
}
</script>

<style lang="sass" scoped>
.page-title
    display: flex
    align-items: center
    margin-top: 25px
    margin-bottom: 40px
    font-size: 30px
    font-weight: 700
</style>
