<template>
    <div>
        <filter-component
            v-if="task === null"
            :props="propFilters"
            label="Filter by tasks"
            class="mb-3"
            :disabled="!hasTaskModule"
            @changed="onChangeFilters"
        ></filter-component>

        <template v-if="isLoading">
            <loader-component></loader-component>
        </template>
        <div class="blocks">
            <block-component>
                <module-block-component
                    module="Tasks"
                    :has-module="hasTaskModule"
                >
                    <div v-for="(value, key) in types"
                         :key="key"
                         class="block__line"
                         v-bind:class="{
                         'striped-row': key % 2 !== 0
                    }">
                    <span class="block__icon">
                        <img
                            v-if="getTypeIcon(value.social)"
                            :src="getTypeIcon(value.social)"
                            class="block__image"
                            alt="icon">
                    </span>

                        <span>
                        {{ value.caption }}
                    </span>

                        <span class="block__income text-center">
                        {{
                                (parseInt(value.income) > 0)
                                    ? '+' + value.income
                                    : value.income
                            }}
                    </span>

                        <span class="text-center">
                        {{ value.total }}
                    </span>
                    </div>
                </module-block-component>
            </block-component>

            <block-component class="completed-tasks-statistics overflow-auto">
                <module-block-component
                    module="Tasks"
                    :has-module="hasTaskModule"
                >
                    <h4 class="block__title">
                        Participant statistics
                    </h4>
                    <div v-for="(value, key) in getStats()"
                         :key="key"
                         class="block__line"
                         v-bind:class="{
                            'striped-row': key % 2 !== 0
                         }">

                    <span>
                        {{ value.caption }}
                    </span>

                        <span></span><span></span>

                        <span class="text-center">
                        {{ value.value }}
                    </span>
                    </div>
                </module-block-component>
            </block-component>
        </div>
    </div>
</template>

<script>
import TelegramChannelIcon from '@images/tasks/tasks_telegram_channel.svg';
import TelegramChatIcon from '@images/tasks/tasks_telegram_chat.svg';
import TwitterIcon from '@images/tasks/tasks_twitter.svg';
import DiscordIcon from '@images/tasks/tasks_discord.svg';

export default {
    name: 'CompletedTasksStatisticsComponent',
    props: {
        task: {
            type: Object,
            default: null
        },
        propFilters: {
            type: Array,
            default: []
        },
        hasTaskModule: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            types: [],
            stats: [],
            filters: []
        }
    },
    mounted() {
        this.uploadCompletedTasksStats();
    },
    methods: {
        async uploadCompletedTasksStats() {
            if (!this.hasTaskModule) {
                this.types = [];
                this.stats = [];
                for (let i = 0; i < 4; i++) {
                    this.types.push({
                        social: 'none',
                        caption: '',
                        income: 0,
                        total: 0
                    });
                    this.stats.push(0);
                }
                return;
            }
            this.isLoading = true;
            let filter = this.filters;
            if (filter.length === 0 && this.task) {
                filter.push({
                    uuid: this.task.uuid
                })
            }
            axios.post('/analytics/completed-tasks-statistics', {
                filters: filter
            }).then((result) => {
                this.types = result.data.types;
                this.stats = result.data.stats;
                this.isLoading = false;
            });
        },
        getTypeIcon(social) {
            switch (social) {
                case 'none':
                    return null;
                case 'telegram':
                    return TelegramChannelIcon;
                case 'twitter':
                    return TwitterIcon;
                case 'discord':
                    return DiscordIcon;
            }
        },
        onChangeFilters(filters) {
            this.filters = filters;
            this.uploadCompletedTasksStats();
        },
        getStats() {
            let results = [];
            let sum = 0;
            let _this = this;
            this.stats.map(function (item, key) {
                sum += item;
                results.push({
                    caption: _this.getStatCaption(key),
                    value: item
                });
            });

            if (this.stats.length > 0) {
                results.unshift({
                    caption: 'Total users',
                    value: sum
                });
            }

            return results;
        },
        getStatCaption(index) {
            if (index === 0) {
                return 'Completed ' + (index + 1) + ' task';
            }
            return 'Completed ' + (index + 1) + ' tasks';
        }
    }
};
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px

.striped-row
    background-color: #F8F8F9 !important

.completed-tasks-statistics
    max-height: 600px

.block
    position: relative
    margin: 0 0 30px 0
    padding: 20px
    background-color: #fff
    font-size: 16px
    font-weight: 500
    border-radius: 10px
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.05)

    &__title
        margin: 0 0 20px 0
        padding: 0
        font-size: 18px
        font-weight: 700

    &__icon
        display: flex
        align-items: center
        justify-content: center
        background-color: #F5F9FE
        width: 40px
        height: 40px
        border-radius: 50%

        &_blue
            background-color: #0091FF
            color: #fff
            font-size: 18px
            font-weight: 700

    &__line
        display: grid
        grid-template-columns: max-content 1fr 60px 60px
        grid-column-gap: 10px
        align-items: center
        padding: 10px 0

    &__income
        padding: 4px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px
</style>
