<template>
    <div class="blocks">
        <block-component>
            <div
                v-for="(value, label) in getInfo()"
                :key="label"
                class="info"
            >
                <span class="info__label">
                    {{ label }}
                </span>

                <span class="info__value">
                    {{ value }}
                </span>
            </div>
        </block-component>

        <block-component>
            <a
                id="qr-code"
                :href="qr"
                download
            >
                <img
                    :src="qr"
                    class="image"
                    alt="icon"
                >
            </a>

            <blue-button-component
                @click="onDownloadClicked"
            >
                Download
            </blue-button-component>
        </block-component>
    </div>

    <block-component>
        <table-component
            :data="tgUsers"
            :columns="['Username', 'First name']"
            :attributes="['username', 'first_name']"
            view
        >
            <template v-slot:pagination>
                <slot name="pagination"></slot>
            </template>
        </table-component>
    </block-component>
</template>

<script>
export default {
    name: 'UtmViewPage',
    props: {
        item: {
            type: Object,
            required: true,
        },
        qr: {
            type: String,
            default: '',
        },
        tgUsers: {
            type: Object,
            required: true,
            default: {},
        },
    },
    methods: {
        getInfo() {
            return {
                'Link to add to the group': this.item.link,
                'The number of conversions': this.item.clicks,
                'Number of unique conversions': this.item.unique_clicks,
            };
        },
        onDownloadClicked() {
            document.getElementById('qr-code').click();
        },
    },
}
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr max-content
    grid-column-gap: 40px

.info
    margin-bottom: 20px
    font-size: 16px

    &__label
        display: block
        margin-bottom: 10px
        color: #666666

    &__value
        display: block
        font-weight: 500

.image
    display: block
    background-color: #fff
    border-radius: 10px
</style>
