<template>
    <page-title-component>
        {{ this.data.first_name }} {{ this.data.last_name }}

        <img
            :src="getVerifyIcon()"
            class="user-icon"
            alt="icon"
        >
    </page-title-component>

    <div class="user__buttons">
        <red-button-component
            v-if="globalBlockLink && !data.is_ban"
            :link="globalBlockLink"
        >
            Global block user
        </red-button-component>

        <green-button-component
            v-else-if="globalUnblockLink"
            :link="globalUnblockLink"
        >
            Global unblock user
        </green-button-component>

        <red-button-component
            v-if="!data.is_banned"
            :link="blockLink"
        >
            Block user
        </red-button-component>

        <green-button-component
            v-else
            :link="unblockLink"
        >
            Unblock user
        </green-button-component>

        <blue-button-component
            v-if="gameSessionsLink"
            :link="gameSessionsLink"
        >
            Game sessions
        </blue-button-component>
    </div>

    <div class="blocks">
        <div class="block">
            <div class="block__header">
                <h4 class="block__title">
                    Personal information
                </h4>
            </div>

            <div
                class="detail"
                v-for="(value, key) in getPersonalInfo()"
            >
                <span class="detail__label">
                    {{ key }}
                </span>

                <span class="detail__value">
                    {{ value }}
                </span>
            </div>
        </div>

        <div>
            <div class="block">
                <div class="block__header">
                    <h4 class="block__title">
                        Referral data
                    </h4>
                </div>

                <div class="detail detail_bordered mb-4">
                    <span class="detail__label">
                        Referral count:
                    </span>

                    <span class="detail__value">
                        {{ data.count_referrals }}
                    </span>
                </div>

                <div class="mb-2">
                    <blue-button-component
                        :link="viewReferralsLink"
                    >
                        View referrals
                    </blue-button-component>
                </div>
            </div>

<!--            <div class="block">-->
<!--                <div class="block__header">-->
<!--                    <h4 class="block__title">-->
<!--                        Game results-->
<!--                    </h4>-->
<!--                </div>-->

<!--                <blue-button-component-->
<!--                    :link="gameStatsLink"-->
<!--                    class="mb-3"-->
<!--                >Game statistics-->
<!--                </blue-button-component>-->

<!--                <blue-button-component>-->
<!--                    Connect this module-->
<!--                </blue-button-component>-->
<!--            </div>-->

            <div class="block">
                <div class="block__header">
                    <h4 class="block__title">
                        Social media
                    </h4>

<!--                    <button class="settings-button"></button>-->
                </div>

                <div class="socials">
                    <a
                        v-for="(social, index) in getSocials()"
                        :key="index"
                        class="socials__link"
                        :href="social.link"
                        target="_blank"
                    >
                        <img
                            :src="social.image"
                            class="socials__icon"
                            alt="icon"
                        >
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="blocks">
        <div class="block">
            <div class="block__header">
                <h4 class="block__title">
                    Wallets
                </h4>
            </div>

            <div
                v-for="(value, key) in getWallets()"
                :key="key"
                class="detail"
            >
                <span class="detail__label">
                    {{ key }}
                </span>

                <span class="detail__value">
                    {{ value }}
                </span>

<!--                <button
                    class="detail__value copy-button"
                    @click="onCopyClicked(value)"
                >
                </button>-->
            </div>
        </div>

        <div class="block">
            <div class="block__header">
                <h4 class="block__title">
                    Bonus balance
                </h4>
            </div>

            <div
                v-for="(value, key) in getBonus()"
                :key="key"
                class="detail detail_bordered"
            >
                <span class="detail__label">
                    {{ key }}
                </span>

                <span class="detail__value">
                    {{ value }}
                </span>
            </div>

            <hr>

            <div class="detail border-bottom-0">
                <span class="detail__label">
                    Total
                </span>

                <span class="detail__value">
                    {{ getTotalBonus() }}
                </span>
            </div>

            <div class="mt-4">
                <blue-button-component :link="addPointsLink">
                    Add points
                </blue-button-component>
            </div>
        </div>
    </div>
    <block-component title="Tasks">
        <module-block-component
            module="Tasks"
            :has-module="hasTaskModule"
        >
            <table-component
                :data="getTasks()"
                :columns="['Name of task', 'Type of task', 'Accrued points', 'Date of execution', 'Progress']"
                :attributes="['name', 'string_type', 'accrued_point', 'date_execution', 'status']"
                sub-row="units"
                :sub-attributes="['name', 'string_type', 'accrued_point', 'date_execution', 'status']"
            >
            </table-component>
        </module-block-component>
    </block-component>
</template>

<script>
import verifyIcon from '@images/check_green.svg';
import notVerifyIcon from '@images/check_red.svg';
import copyIcon from '@images/copy.svg'

import discordIcon from '@images/socials/discord.svg';
import instagramIcon from '@images/socials/instagram.svg';
import telegramIcon from '@images/socials/telegram.svg';
import tiktokIcon from '@images/socials/tiktok.svg';
import twitterIcon from '@images/socials/twitter.svg';

export default {
    name: 'TelegramUserViewPage',
    props: {
        addPointsLink: {
            type: String,
            required: true,
        },
        blockLink: {
            type: String,
            required: true,
        },
        unblockLink: {
            type: String,
            required: true,
        },
        globalBlockLink: {
            type: String,
            required: false,
        },
        globalUnblockLink: {
            type: String,
            required: false,
        },
        gameSessionsLink: {
            type: String,
            required: true,
        },
        viewReferralsLink: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
            default: {
                captcha_passed: '',
                language_code: '',
                created_at: '',
                tid: '',
                wallets: [],
                count_referrals: 0,
                first_name: '',
                last_name: '',
            },
        },
        gameStatsLink: {
            type: String
        },
        tasks: {
            type: Array,
            default: []
        },
        wallets: {
            type: Array,
            default: []
        },
        balance: {
            type: Object,
            default: {
                total: 0,
                tasks: 0,
                refs: 0,
            }
        },
        socials: {
            type: Array,
            default: []
        },
        hasTaskModule: {
            type: Boolean,
            default: false
        },
        customUsername: {
            type: String
        },
    },
    data() {
        return {
            copyIcon: copyIcon,
        };
    },
    methods: {
        getVerifyIcon() {
            if (this.data.captcha_passed.length === 0) {
                return notVerifyIcon;
            }

            return verifyIcon;
        },
        getPersonalInfo() {
            return {
                'Username': this.data.username,
                'Language code': this.data.language_code,
                'System language': this.data.system_language ?? '-',
                'Joined': this.data.created_at,
                'Joined by season': this.data.season_name ?? '-',
                'The engagement channel': '-',
                'Email': this.data.email ?? '-',
                'Phone number': this.data.phone ?? '-',
                'Custom username': this.customUsername ?? '-',
            };
        },
        getSocials() {
            let socials = [];
            this.socials.map(function (item) {
                let icon = null;
                switch (item.social) {
                    case 'telegram':
                        icon = telegramIcon;
                        break;
                    case 'twitter':
                        icon = twitterIcon;
                        break;
                    case 'discord':
                        icon = discordIcon;
                        break;
                }

                socials.push({
                    link: item.link,
                    image: icon
                })
            })

            return socials;

            // return [
            //     discordIcon,
            //     instagramIcon,
            //     telegramIcon,
            //     tiktokIcon,
            //     twitterIcon,
            // ];
        },
        getBonus() {
            return {
                //'For the game': this.balance.tasks,
                'For the tasks': this.balance.tasks,
                'For the referral program': this.balance.refs,
                'Withdrawn points': this.balance.withdrawal,
                'For creatives': this.balance.creatives,
                'For checking creatives': this.balance.creatives_checks,
                'Manual:': this.balance.custom,
                //'Cashback': 99,
            };
        },
        getTotalBonus() {
            return this.balance.total
        },
        getWallets() {
            return this.wallets;
        },
        onCopyClicked(value) {
            navigator.clipboard.writeText(value);
        },
        getTasks() {
            if (!this.hasTaskModule) {
                let results = [];
                for (let i = 0; i < 2; i++) {
                    results.push({
                        name: '',
                        string_type: '',
                        string_price: '',
                        date_execution: '',
                        status: '',
                        units: [
                            {
                                name: '',
                                string_type: '',
                                string_price: '',
                                date_execution: '',
                                status: '',
                            }
                        ]
                    })
                }
                return results;
            }

            return this.tasks;
        }
    },
}
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 370px
    grid-column-gap: 30px

.block
    margin-bottom: 30px
    padding: 30px
    background-color: #fff
    border-radius: 10px

    &_demo
        text-align: center

    &__header
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 20px
        font-size: 20px

    &__title
        font-size: 22px

.detail
    display: flex
    align-items: center
    justify-content: space-between
    padding: 10px 0
    font-size: 16px
    border-bottom: 1px solid #E7E7E7

    &:last-child
        border-bottom: 0

    &_bordered
        border-bottom: 0

    &__label
        color: #666666

    &__value
        font-weight: 500

.user
    &__icon
        width: 30px
        margin-left: 10px

    &__buttons
        display: grid
        grid-template-columns: max-content max-content max-content 1fr
        grid-column-gap: 10px
        margin-bottom: 40px

.socials
    display: grid
    grid-template-columns: repeat(5, max-content)
    grid-column-gap: 10px

    &__icon
        padding: 10px
        background-color: #0091FF
        border-radius: 50%
        max-width: 100%

.user-icon
    width: 30px
    margin-left: 10px

.copy-button
    background-color: transparent
    border: 0

.settings-button
    width: 34px
    height: 34px
    background-color: #0091FF
    background-image: url('@images/settings.svg')
    background-position: center
    background-repeat: no-repeat
    border: 0
    border-radius: 5px
</style>
