<template>
    <custom-editor-component
        :value="text"
        :name="getName()"
    ></custom-editor-component>
</template>

<script>
export default {
    name: 'TelegramTextMessageComponent',
    props: {
        index: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        view: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getName() {
            return 'tg_units[' + this.index + '][text]';
        },
    },
};
</script>

<style lang="sass" scoped>
</style>
