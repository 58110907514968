<template>
    <block-component>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col-9">
                        <h2>
                            ChatBoost checklist
                        </h2>

                        <p>
                            Follow our tips for setting up a chatbot for your project
                        </p>
                    </div>

                    <div class="col-3 text-end">
                        <span class="percent">
                            {{ percent }}%
                        </span>
                    </div>
                </div>

                <div
                    :class="getChecklistClass(value)"
                    v-for="(value, key) in data"
                    :key="value"
                    @click="onLineClicked(key)"
                >
                    <img
                        v-if="value"
                        class="line__image"
                        src="@images/check_green.svg"
                        alt="ico"
                    >

                    <img
                        v-else
                        class="line__image"
                        src="@images/check.svg"
                        alt="icon"
                    >

                    {{ getChecklistName(key) }}

                    <img class="line__chevron" src="@images/chevron_right_black.svg" alt="icon">
                </div>
            </div>

            <div class="col">
                <h2>
                    Video
                </h2>

                <p>
                    Watch a tutorial video on the basic features of the ChatBoost personal account
                </p>

                <iframe
                    :src="video"
                    class="rounded"
                    width="100%"
                    height="300"
                    title="Как настроить Telegram магазин на сервисе Dostavkinbot.online"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    </block-component>

    <div class="blocks">
        <dashboard-block-component
            :icon="getUsersIcon()"
            :data="this.users.data"
            :isLoading="this.users.isLoading"
            caption="users"
        ></dashboard-block-component>

        <dashboard-block-component
            :icon="getCaptchaIcon()"
            :data="this.captcha.data"
            :isLoading="this.captcha.isLoading"
            caption="passed captcha"
        ></dashboard-block-component>

        <dashboard-block-component
            :icon="getReferralIcon()"
            :data="this.referral.data"
            :isLoading="this.referral.isLoading"
            caption="referral users"
        ></dashboard-block-component>

        <dashboard-block-component
            :icon="0"
            :data="{}"
            :isLoading="false"
            caption=""
            blue
            @click="onAnalyticsClicked"
        ></dashboard-block-component>
    </div>
</template>

<script>
import usersIcon from '@images/dashboard/dashboard_users.svg';
import captchaIcon from '@images/dashboard/dashboard_captcha.svg';
import referralIcon from '@images/dashboard/dashboard_referral.svg';
import DashboardBlockComponent from "./DashboardBlockComponent";

export default {
    name: 'DashboardPage',
    components: {
        DashboardBlockComponent,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        percent: {
            type: Number,
            required: true,
        },
        video: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.users.isLoading = true;
        axios.post('/dashboard/users')
            .then((result) => {
                this.users.data = result.data;
                this.users.isLoading = false;
            });

        this.captcha.isLoading = true;
        axios.post('/dashboard/captcha')
            .then((result) => {
                this.captcha.data = result.data;
                this.captcha.isLoading = false;
            });

        this.referral.isLoading = true;
        axios.post('/dashboard/referral')
            .then((result) => {
                this.referral.data = result.data;
                this.referral.isLoading = false;
            });
    },
    methods: {
        getChecklistName(key) {
            if (key === 'create_account') {
                return 'Create an account';
            } else if (key === 'project_information') {
                return 'Add information about the project';
            } else if (key === 'community_bot') {
                return 'Connect Community bot';
            } else if (key === 'referral_program') {
                return 'Set up a referral program';
            } else if (key === 'unique_link') {
                return 'Create unique link for each advertising channel';
            } else if (key === 'support_bot') {
                return 'Connect and turn on the Support bot to your Telegram chat';
            } else if (key === 'mailing') {
                return 'Set up your first mailing campaign for bot users'
            }
        },
        getChecklistClass(value) {
            if (value) {
                return 'line line_disabled';
            } else {
                return 'line';
            }
        },
        getUsersIcon() {
            return usersIcon;
        },
        getCaptchaIcon() {
            return captchaIcon;
        },
        getReferralIcon() {
            return referralIcon;
        },
        onAnalyticsClicked() {
            window.location.href = '/analytics';
        },
        onLineClicked(key) {
            if (this.data[key]) {
                return;
            }

            if (key === 'mailing') {
                window.location.href = '/mailing';
            } else if (key === 'referral_program') {
                window.location.href = '/referrals';
            } else if (key === 'support_bot') {
                window.location.href = '/telegram/bot/settings';
            } else if (key === 'unique_link') {
                window.location.href = '/community-utm';
            }
        },
    },
    data() {
        return {
            users: {
                data: {},
                isLoading: false,
            },
            captcha: {
                data: {},
                isLoading: false,
            },
            referral: {
                data: {},
                isLoading: false,
            },
        };
    },
}
</script>

<style lang="sass" scoped>
.percent
    display: flex
    align-items: center
    justify-content: center
    color: #00BA8F
    font-size: 36px
    font-weight: 600

.line
    display: grid
    grid-template-columns: max-content 1fr max-content
    grid-column-gap: 20px
    align-items: center
    padding: 10px 20px 10px 0
    font-size: 16px
    font-weight: 700
    border-bottom: 1px solid #E6EAF0

    &:hover
        cursor: pointer
        background-color: rgba(0, 0, 0, 0.03)

    &_disabled
        color: #777777
        text-decoration: line-through
        font-weight: 400

        &:hover
            background-color: transparent
            cursor: default

.blocks
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-column-gap: 30px
</style>
