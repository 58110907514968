<template>
    <div>
        <vue-recaptcha
            ref="recaptcha"
            :sitekey="siteKey"
            @verify="verify"
            @expired="onCaptchaExpired"
        />
    </div>

</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';

export default {
    name: 'CaptchaComponent',
    components: {
        VueRecaptcha,
    },
    props: [
        'captchaUuid',
        'siteKey'
    ],
    methods: {
        verify (recaptchaToken) {
            axios.post('api/captcha/verify', {
                recaptchaToken: recaptchaToken,
                captchaUuid: this.captchaUuid
            }).then(response => {
                if (response.data.status) {
                    let tg = window.Telegram.WebApp;
                    if (response.data.link) {
                        tg.openTelegramLink(response.data.link);
                    } else {
                        tg.close();
                    }
                }
            });
        },
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },

    }
};
</script>

<style lang="sass" scoped>

</style>
