<template>
    <template v-if="blue">
        <div class="block block_blue">
            <img src="@images/dashboard/dashboard_analytics.svg" alt="icon">

            <h4 class="block__value">
                Detailed <br> analytics
            </h4>
        </div>
    </template>

    <template v-else>
        <div class="block">
            <template v-if="!isLoading">
                <div class="block__image">
                    <img
                        :src="icon"
                        class="block__icon"
                        alt="icon">
                </div>

                <div class="block__text">
                    <h4 class="block__value">
                        {{ data.total }}
                    </h4>

                    <p class="block__name">
                        {{ caption }}
                    </p>
                </div>

                <div class="block__plus">
                    {{ (parseInt(data.income) > 0)
                        ? '+' + data.income
                        : data.income
                    }}
                </div>
            </template>

            <loader-component v-else></loader-component>
        </div>
    </template>
</template>

<script>
export default {
    name: 'DashboardBlockComponent',
    props: {
        icon: {
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        caption: {
            type: String,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        blue: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="sass" scoped>
.block
    position: relative
    display: grid
    grid-template-columns: repeat(2, max-content)
    grid-column-gap: 20px
    align-items: center
    padding: 24px
    background-color: #fff
    border-radius: 10px
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05)

    &_blue
        background: linear-gradient(270.61deg, #007EDD 4.6%, #159AFF 98.42%), linear-gradient(279.67deg, #3639A4 -43.88%, #2F80ED 94.62%), #0091FF
        color: #fff

        &:hover
            cursor: pointer

    &__plus
        position: absolute
        top: 10px
        right: 10px
        padding: 4px 8px
        background-color: #28D381
        color: #fff
        font-size: 10px
        font-weight: 700
        border-radius: 20px

    &__image
        display: flex
        align-items: center
        justify-content: center
        width: 58px
        height: 58px
        background-color: #F5F9FE
        border-radius: 50%

    &__icon
        display: block

    &__value
        margin-bottom: 10px
        padding: 0
        font-size: 24px
        font-weight: 600

    &__name
        margin: 0
        padding: 0
        font-size: 16px
</style>
