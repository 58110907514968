<template>
    <div
        :class="blockClass"
    >
        <h5
            v-if="title"
            class="block__title"
        >
            {{ title }}
        </h5>

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BlockComponent',
    props: {
        title: {
            type: String,
        },
        withShadow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            blockClass: {
                'block': true,
                'block_shadow': this.withShadow,
            },
        };
    },
};
</script>

<style lang="sass" scoped>
.block
    margin-bottom: 20px
    padding: 40px
    background-color: #fff
    border-radius: 10px

    &_shadow
        box-shadow: 15px 15px 35px rgb(0, 0, 0, 0.2)

    &__title
        margin-bottom: 20px
        color: #0c3979
        font-size: 24px
        font-weight: 600
</style>
