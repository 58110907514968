<template>
    <p class="text-secondary" v-if="mailing.is_processing === 1">
        Counting recipients is started. The number of recipients would be changed.
    </p>

    <div class="blocks">
        <div class="block">
            <div class="block__image">
                <img
                    src="@images/dashboard/dashboard_users.svg"
                    class="block__icon"
                    alt="icon"
                >
            </div>

            <div class="block__text">
                <h4 class="block__value">
                    {{ countShouldReceive}}
                </h4>

                <p class="block__name">
                    Users should receive
                </p>
            </div>

            <a
                :href="shouldReceiveUrl"
                class="view"
            ></a>
        </div>

        <div class="block">
            <div class="block__image">
                <img
                    src="@images/dashboard/dashboard_captcha.svg"
                    class="block__icon"
                    alt="icon"
                >
            </div>

            <div class="block__text">
                <h4 class="block__value">
                    {{ received }}
                </h4>

                <p class="block__name">
                    Users received
                </p>
            </div>

            <a
                :href="receivedUrl"
                class="view"
            ></a>
        </div>
    </div>

    <div
        v-if="isRoot"
        class="blocks mt-4"
    >
        <div class="block">
            <div class="block__image">
                <img
                    src="@images/analytics/analytics_overview.svg"
                    class="block__icon"
                    alt="icon"
                >
            </div>

            <div class="block__text">
                <h4 class="block__value">
                    <template v-if="filterTime">
                        {{ filterTime }}
                    </template>

                    <template v-else>
                        Not counted
                    </template>
                </h4>

                <p class="block__name">
                    Filter time
                </p>
            </div>
        </div>

        <div class="block">
            <div class="block__image">
                <img
                    src="@images/analytics/analytics_overview.svg"
                    class="block__icon"
                    alt="icon"
                >
            </div>

            <div class="block__text">
                <h4 class="block__value">
                    <template v-if="mailingTime">
                        {{ mailingTime }}
                    </template>

                    <template v-else>
                        Not sending
                    </template>
                </h4>

                <p class="block__name">
                    Mailing time
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MailingBlockComponent',
    props: {
        shouldReceive: {
            type: Number,
            required: true,
        },
        shouldReceiveUrl: {
            type: String,
            required: true,
        },
        received: {
            type: Number,
            required: true,
        },
        receivedUrl: {
            type: String,
            required: true,
        },
        isRoot: {
            type: Boolean,
            required: true,
        },
        mailing: {
            type: Object,
            required: true,
        },
        mailingTime: {
            type: String,
            required: false,
        },
        filterTime: {
            type: String,
            required: false,
        },
    },
    mounted() {
        if (this.mailing.is_processing === 1) {
            setTimeout(this.fetchCountShouldReceive, 5000);
        }

        this.countShouldReceive = this.shouldReceive;
    },
    methods: {
        fetchCountShouldReceive() {
            axios.post('/mailing/view/' + this.mailing.uuid + '/should-receive/json')
                .then((result) => {
                    this.countShouldReceive = result.data.count_should_receive;

                    if (result.data.mailing.is_processing === 1) {
                        setTimeout(this.fetchCountShouldReceive, 5000);
                    }
                });
        },
    },
    data() {
        return {
            countShouldReceive: 0,
        };
    },
}
</script>

<style lang="sass" scoped>
.blocks
    display: grid
    grid-template-columns: 1fr 1fr max-content
    grid-column-gap: 20px

.block
    display: grid
    grid-template-columns: max-content 1fr max-content
    grid-column-gap: 20px
    align-items: center
    padding: 24px
    background-color: #fff
    border-radius: 10px
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05)

    &__value
        margin-bottom: 10px
        padding: 0
        font-size: 24px
        font-weight: 600

    &__name
        margin: 0
        padding: 0
        font-size: 16px

    &__image
        display: flex
        align-items: center
        justify-content: center
        width: 58px
        height: 58px
        background-color: #F5F9FE
        border-radius: 50%

    &__icon
        display: block

.view
    display: block
    width: 30px
    height: 30px
    background-color: #0091FF
    background-image: url('@images/chevron_right.svg')
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%

    &:hover
        background-color: #2975af
</style>
