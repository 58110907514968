<template>
    <template v-if="view">
        <img
            :src="image"
            :alt="image"
            class="img-fluid"
        >
    </template>

    <template v-else>
        <input-form-group-component
            :name="getName()"
            label="Image"
            type="file"
            accept="image/png, image/jpeg, image/gif"
        ></input-form-group-component>
        <input
            :name="getIdName()"
            :value="id"
            type="hidden"
        >
    </template>
</template>

<script>
export default {
    name: 'TelegramImageMessageComponent',
    props: {
        index: {
            type: Number,
            required: true,
        },
        id: {
            type: Number,
            default: 0,
        },
        image: {
            type: String,
            default: '',
        },
        view: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getName() {
            return 'tg_units[' + this.index + '][image]';
        },
        getIdName() {
            return 'tg_units[' + this.index + '][image_id]';
        },
    },
};
</script>

<style lang="sass" scoped>

</style>
