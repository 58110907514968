<template>
    <div class="mb-4 d-flex">
        <div class="w-25 me-2">
            <blue-button-component
                @click="onCreateClicked"
                button
            >
                Create new message
            </blue-button-component>
        </div>

        <slot></slot>
    </div>

    <template v-if="hasTgUnits()">
        <TelegramMessageComponent
            v-for="(tgUnit, index) in tgUnits"
            :key="index"
            :index="index"
            :tg-unit-types="tgUnitTypes"
            :tg-unit="tgUnit"
            :view="view"
            :tg-screens="tgScreens"
        ></TelegramMessageComponent>
    </template>

    <h5
        v-else
        class="text-center"
    >
        Empty
    </h5>
</template>

<script>
import TelegramMessageComponent from './TelegramMessageComponent';

export default {
    name: 'TelegramMessagesComponent',
    components: {
        TelegramMessageComponent,
    },
    created() {
        this.tgUnits = this.items;
    },
    props: {
        tgUnitTypes: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            default: [],
        },
        view: {
            type: Boolean,
            default: false,
        },
        tgScreens: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            tgUnits: [],
        };
    },
    methods: {
        hasTgUnits() {
            return this.tgUnits.length > 0;
        },
        onCreateClicked() {
            this.tgUnits.push({});
        },
    },
};
</script>

<style lang="sass" scoped>
</style>
