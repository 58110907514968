<template>
    <div class="mb-4 d-flex">
        <div class="w-25 me-2">
            <blue-button-component
                @click="onAddFormClicked"
                button
            >
                Add form
            </blue-button-component>
        </div>

        <slot></slot>
    </div>

    <telegram-form-component
        v-for="(datum, index) in data"
        :key="index"
        :index="index"
        :types="types"
        :item="getItem(index)"
    ></telegram-form-component>
</template>

<script>
import TelegramFormComponent from './TelegramFormComponent'

export default {
    name: 'TelegramFormsComponent',
    components: {
        TelegramFormComponent,
    },
    created() {
        this.data = this.items;
    },
    props: {
        types: {
            type: Object,
            default: {},
        },
        items: {
            type: Object,
            default: {},
        }
    },
    data() {
        return {
            data: [],
        };
    },
    methods: {
        onAddFormClicked() {
            this.data.push({});
        },
        getItem(index) {
            if (this.data.hasOwnProperty(index)) {
                return this.data[index];
            }

            return {};
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
