<template>
    <div class="row">
        <div class="row">

            <div class="col">
                <input-form-group-component
                    label="Task name"
                    name="name"
                    type="text"
                    maxlength=255
                    placeholder="Enter the name of the task"
                    :value="unit.name"
                    :error="errors.name"
                    required
                ></input-form-group-component>
            </div>
            <div class="col">
                <select-form-group-component
                    v-on:change="changeSocial"
                    :data="this.taskSocials"
                    :selected="unit.social_id"
                    name="social_id"
                    label="Social"
                    :error="errors.social_id"
                    required
                ></select-form-group-component>
            </div>

        </div>

        <div class="row">

            <div class="col">
                <text-area-form-group-component
                    label="Description"
                    placeholder="Write description of tasks group"
                    name="description"
                    maxlength=1000
                    rows="17"
                    :value="unit.description"
                    :error="errors.description"
                    required
                ></text-area-form-group-component>
            </div>
            <div class="col">
                <select-form-group-component
                    v-on:change="changeType"
                    :data="this.selectedTaskTypes"
                    :selected="unit.type_id"
                    name="type_id"
                    label="Task type"
                    :error="errors.type_id"
                    required
                ></select-form-group-component>

                <!-- Link input for tasks -->
                <input-form-group-component
                    v-if="!(this.unit.social_id === 3 && this.unit.type_id === 4)
                          && !(this.unit.social_id === 1 && this.unit.type_id !== 1)"
                    :label="this.linkCaption"
                    name="link"
                    type="url"
                    :placeholder="this.linkPlaceholder"
                    :error="errors.link || this.linkError"
                    :value="unit.data.link"
                    required
                    @input="changeLink"
                ></input-form-group-component>

                <!-- Twitter hashtag -->
                <input-form-group-component
                    v-if="this.unit.social_id === 3 && this.unit.type_id === 4"
                    label="hashtag"
                    name="hashtag"
                    type="text"
                    placeholder="#chatboost"
                    :value="this.unit.data.hashtag"
                    :error="errors.hashtag"
                    required
                ></input-form-group-component>

                <!-- Tg forms  -->
                <select-form-group-component
                    v-if="this.unit.social_id === 1 && this.unit.type_id === 2"
                    :data="this.tgForms"
                    :selected="unit.data.tg_screen_id"
                    name="tg_screen_id"
                    label="Tg form"
                    :error="errors.tg_screen_id"
                    required
                ></select-form-group-component>

                <input-form-group-component
                    label="Points"
                    name="points"
                    type="number"
                    max="2147483647"
                    placeholder="50000"
                    :value="this.unit.points"
                    :error="errors.points"
                    required
                ></input-form-group-component>

                <!-- Telegram (Select tg channel/chat) -->
                <select-form-group-component
                    v-if="unit.social_id === 2"
                    :data="this.telegramGroups"
                    :selected="this.unit.data.telegram_group_id"
                    name="telegram_group_id"
                    label="Select tg channel/chat"
                    :error="errors.telegram_group_id"
                    required
                ></select-form-group-component>

                <!-- Discord (Server select)-->
                <a :href="this.discordBotLink"
                   v-if="this.unit.social_id === 4 && this.unit.type_id === 1 && this.discordBotLink">Add bot to server</a>
                <select-form-group-component
                    v-if="this.unit.social_id === 4 && this.unit.type_id === 1"
                    :data="this.discordServers"
                    :selected="this.unit.data.discord_server_id"
                    name="discord_server_id"
                    label="Select discord server"
                    :error="errors.discord_server_id"
                    required
                ></select-form-group-component>

                <!-- Link -->
                <input-form-group-component
                    v-if="this.unit.social_id === 1 && this.unit.type_id === 1"
                    label="Delay in minutes"
                    name="delay"
                    type="number"
                    max="2147483647"
                    default="0"
                    placeholder="0"
                    :value="this.unit.data.delay"
                    :error="errors.delay"
                    required
                ></input-form-group-component>

                <!-- Twitter -->
                <!-- Retweet count -->
                <input-form-group-component
                    v-if="this.unit.social_id === 3 && this.unit.type_id === 5"
                    label="Retweet count"
                    name="retweet_count"
                    type="number"
                    max="2147483647"
                    min="1"
                    default="1"
                    placeholder="1"
                    :value="this.unit.data.retweet_count"
                    :error="errors.retweet_count"
                    required
                ></input-form-group-component>

                <!-- Replies count -->
                <input-form-group-component
                    v-if="this.unit.social_id === 3 && this.unit.type_id === 6"
                    label="Replies count"
                    name="replies_count"
                    type="number"
                    max="2147483647"
                    min="1"
                    default="1"
                    placeholder="1"
                    :value="this.unit.data.replies_count"
                    :error="errors.replies_count"
                    required
                ></input-form-group-component>
            </div>

        </div>

        <div class="row">

            <div class="col">
                <!-- Custom username for link -->
                <text-area-form-group-component
                    v-if="this.unit.social_id === 1"
                    :label="getRequestMessageLabel()"
                    :placeholder="getRequestMessagePlaceholder()"
                    name="request_message"
                    :value="this.unit.request_message"
                    maxlength=255
                    :error="errors.request_message"
                ></text-area-form-group-component>

                <!-- Twitter -->
                <text-area-form-group-component
                    v-if="this.unit.social_id === 3"
                    label="Twitter username request (system message)"
                    placeholder="Send your Twitter username"
                    name="request_message"
                    :value="this.unit.request_message"
                    maxlength=255
                    :error="errors.request_message"
                    required
                ></text-area-form-group-component>

                <!-- Discord -->
                <text-area-form-group-component
                    v-if="this.unit.social_id === 4"
                    label="Discord username request (system message)"
                    placeholder="Send your Discord username"
                    name="request_message"
                    maxlength=255
                    :value="this.unit.request_message"
                    :error="errors.request_message"
                    required
                ></text-area-form-group-component>


                <text-area-form-group-component
                    label="Task completed (system message)"
                    placeholder="Task done, move on to the next task"
                    name="complete_message"
                    maxlength=255
                    :value="this.unit.complete_message"
                    :error="errors.complete_message"
                    required
                ></text-area-form-group-component>

                <input-form-group-component
                    :label="getTaskCompletedLabel()"
                    :placeholder="getTaskCompletedPlaceholder()"
                    name="complete_button"
                    maxlength=255
                    :value="this.unit.complete_button"
                    :error="errors.complete_button"
                ></input-form-group-component>
            </div>

            <div class="col">
                <text-area-form-group-component
                    v-if="unit.social_id === 1"
                    :label="getCompleteRequestMessageLabel()"
                    :placeholder="getCompleteRequestMessagePlaceholder()"
                    name="complete_request_message"
                    maxlength=255
                    :value="this.unit.complete_request_message"
                    :error="errors.complete_request_message"
                ></text-area-form-group-component>

                <text-area-form-group-component
                    v-if="unit.social_id === 3 || unit.social_id === 4"
                    label="Username has been saved (system message)"
                    placeholder="Username saved, please wait for verification or move on to next tasks"
                    name="complete_request_message"
                    maxlength=255
                    :value="this.unit.complete_request_message"
                    :error="errors.complete_request_message"
                ></text-area-form-group-component>

                <text-area-form-group-component
                    label="Task not completed (system message)"
                    placeholder="Task not completed"
                    name="not_complete_message"
                    maxlength=255
                    :value="this.unit.not_complete_message"
                    :error="errors.not_complete_message"
                    required
                ></text-area-form-group-component>

                <input-form-group-component
                    v-if="!(this.unit.social_id === 1 && (this.unit.type_id === 2 || this.unit.type_id === 3|| this.unit.type_id === 4))"
                    label="Going to do button"
                    placeholder="🚀 Going to do"
                    name="going_button"
                    maxlength=255
                    :value="this.unit.going_button"
                    :error="errors.going_button"
                    required
                ></input-form-group-component>
            </div>

        </div>
        <div class="row">
            <check-box-form-group-component
                name="is_for_referral"
                label="Required to be a referral"
                :checked="unit.is_for_referral"
            ></check-box-form-group-component>
        </div>
    </div>
</template>

<script>
export default {
    name: "TaskUnitViewPage",
    props: {
        taskUnit: {
            type: Object,
            required: false
        },
        telegramGroups: {
            type: Object,
            default: []
        },
        discordServers: {
            type: Object,
            default: []
        },
        discordBotLink: {
            type: String,
            default: ""
        },
        taskSocials: {
            type: Object,
            default: []
        },
        taskTypes: {
            type: Object,
            default: []
        },
        oldValues: {
            type: Array,
            default: []
        },
        errors: {
            type: Array,
            default: []
        },
        tgForms: {
            type: Array,
            default: [{validation_method:""}]
        }
    },
    data() {
        return {
            unit: {
                name: this.oldValues.name || "",
                description: this.oldValues.description || "",
                social_id: Number.parseInt(this.oldValues.social_id) || 1,
                type_id: Number.parseInt(this.oldValues.type_id) || 1,
                is_for_referral: this.oldValues.is_for_referral ,
                data: {
                    link: this.oldValues.link || "",
                    telegram_group_id: Number.parseInt(this.oldValues.telegram_group_id) || 1,
                    discord_server_id: Number.parseInt(this.oldValues.discord_server_id) || 1,
                    hashtag: this.oldValues.hashtag || "",
                    delay: Number.parseInt(this.oldValues.delay) || 0,
                    retweet_count: Number.parseInt(this.oldValues.retweet_count) || 0,
                    replies_count: Number.parseInt(this.oldValues.replies_count) || 0,
                    tg_screen_id: Number.parseInt(this.oldValues.tg_screen_id) || 0,
                },
                points: Number.parseInt(this.oldValues.points) || 0,
                complete_message: this.oldValues.complete_message || "",
                complete_button: this.oldValues.complete_button || "",
                complete_request_message: this.oldValues.complete_request_message || "",
                not_complete_message: this.oldValues.not_complete_message || "",
                going_button: this.oldValues.going_button || "",
                request_message: this.oldValues.request_message || "",
            },
            selectedTaskTypes: [],
            linkPlaceholder: '',
            linkCaption: '',
            linkError: ''
        };
    },
    mounted() {
        if (this.taskUnit) {
            this.unit = this.taskUnit;
        }

        this.selectedTaskTypes = this.taskTypes[this.unit.social_id];
        this.setLinkPlaceholder();
        this.setLinkCaption();
    },
    methods: {
        changeSocial(e) {
            this.unit.social_id = Number(e.target.value);

            this.selectedTaskTypes = this.taskTypes[this.unit.social_id];
            this.unit.type_id = 1;
        },
        changeType(e) {
            this.unit.type_id = Number(e.target.value);
        },
        changeLink(value) {
            if (Number.isInteger(value.length)) {
                this.unit.data.link = value;
            }
        },
        setLinkPlaceholder() {
            let social_id = this.unit.social_id;
            let type_id = this.unit.type_id;

            if (social_id === 1) {
                this.linkPlaceholder = 'https://google.com';
            } else if (social_id === 2) {
                this.linkPlaceholder = 'https://t.me/';
            } else if (social_id === 3) {
                if (type_id === 2 || type_id === 3) {
                    this.linkPlaceholder = 'https://twitter.com/Twitter/status/123456789';
                } else {
                    this.linkPlaceholder = 'https://twitter.com/Twitter';
                }
            } else if (social_id === 4) {
                this.linkPlaceholder = 'https://discord.gg/';
            }
        },
        setLinkCaption() {
            let social_id = this.unit.social_id;
            let type_id = this.unit.type_id;

            if (social_id === 1) {
                this.linkCaption = 'Link';
            } else if (social_id === 2) {
                this.linkCaption = 'Link to chat/channel';
            } else if (social_id === 3) {
                if (type_id === 2 || type_id === 3) {
                    this.linkCaption = 'Link to tweet';
                } else {
                    this.linkCaption = 'Link to Twitter profile';
                }
            } else if (social_id === 4) {
                this.linkCaption = 'Link to Discord server';
            }
        },
        getLinkError() {
            let social_id = this.unit.social_id;
            let type_id = this.unit.type_id;
            let link = this.unit.data.link;

            let pattern = '';
            if (social_id !== 3) {
                return '';
            } else {
                if (type_id === 2 || type_id === 3) {
                    pattern = '^https:\\/\\/twitter\\.com\\/\\w+\\/status\\/\\d+(\\?.+)?$';

                    let regex = new RegExp(pattern);
                    if (!regex.test(link)) {
                        return 'Enter link to tweet';
                    }
                } else if (type_id !== 4) {
                    pattern = '^https:\\/\\/twitter\\.com\\/\\w+\\/?$';

                    let regex = new RegExp(pattern);
                    if (!regex.test(link)) {
                        return 'Enter link to twitter profile';
                    }
                }
            }

            return '';
        },
        getTaskCompletedLabel() {
            if (this.unit.social_id === 1 && this.unit.type_id === 2) {
                return 'Button enter form value';
            }

            return 'Task completed button';
        },
        getTaskCompletedPlaceholder() {
            if (this.unit.social_id === 1 && this.unit.type_id === 2) {
                return 'Enter';
            }

            return '✅ Task completed';
        },
        getRequestMessageLabel() {
            if (this.unit.social_id === 1 && this.unit.type_id === 3) {
                return 'Custom link request (system message)';
            }
            if (this.unit.social_id === 1 && this.unit.type_id === 4) {
                return 'Custom image request (system message)';
            }

            return 'Custom username request (system message)';
        },
        getRequestMessagePlaceholder() {
            if (this.unit.social_id === 1 && this.unit.type_id === 3) {
                return 'Send your Custom link';
            }
            if (this.unit.social_id === 1 && this.unit.type_id === 4) {
                return 'Send your Custom image';
            }

            return 'Send your Custom username';
        },
        getCompleteRequestMessageLabel() {
            if (this.unit.social_id === 1 && this.unit.type_id === 3) {
                return 'Link has been saved (system message)';
            }
            if (this.unit.social_id === 1 && this.unit.type_id === 4) {
                return 'Image has been saved (system message)';
            }

            return 'Username has been saved (system message)';
        },
        getCompleteRequestMessagePlaceholder() {
            if (this.unit.social_id === 1 && this.unit.type_id === 3) {
                return 'Link saved, please wait for verification or move on to next tasks';
            }
            if (this.unit.social_id === 1 && this.unit.type_id === 4) {
                return 'Image saved, please wait for verification or move on to next tasks';
            }

            return 'Username saved, please wait for verification or move on to next tasks';
        },
    },
    watch: {
        unit: {
            handler: function () {
                this.setLinkPlaceholder();
                this.setLinkCaption();

                this.linkError = this.getLinkError();
            },
            deep: true
        }
    }

}
</script>

<style scoped>

</style>
