`<template>
    <div class="logo">
        <a class="logo__link" href="/">
            <img class="logo__image" src="@images/logo.svg" alt="logo">
        </a>
    </div>
</template>

<script>
export default {
    name: 'LogoComponent'
}
</script>

<style lang="sass" scoped>
.logo
    text-align: center

    &__image
        max-width: 100%
</style>
